/* eslint-disable */
import React, { useState,useContext,useEffect } from "react";
import { Form, Button, Row, Col } from 'react-bootstrap';
import { Link,useParams } from "react-router-dom";
import { Scrollbars } from 'react-custom-scrollbars-2';
import Select from 'react-select';
import CheckValid from "../../Common/CheckValid";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import { WebSocketContext } from "../../../App";
import {useSelector,useDispatch} from "react-redux"
import wsSend_request from "../../../Api/ws/ws_request";
import CommonewModel from "../../Common/CommonewModel";
import AmountNumberFormat from "../../Common/AmountNumberFormat";
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";

const DebitCreditNotesDetailPage = (props) => {
    const { roomId } = useParams();
    const { device_id, AllDealerFranchiseTypeListAll,ReasonTypeList,ReasonList,franchiseTypeListAll,ClearFormSet } = useSelector((state) => state.adminReducers);
    const FranchiseUser = AllDealerFranchiseTypeListAll?.list?.filter((ele)=>ele?.is_active ===true && ele?.id === roomId)?.map((item)=> item)
    const [franchise_Code, setFranchiseCode] = useState("");
    const [name, setName] = useState("");
    const [available_balance, setAvailableBalance] = useState("");
    const [reason_type, setReasonType] = useState("");
    const [remark, setRemark] = useState("");
    const [adjust_amount, setAdjustAmount] = useState("");
    const [reason, setReason] = useState("");
    const [adjust_type, setAdjustType] = useState("");
    const [adjust_type_error,setAdjustTypeError] = useState("")
    const [reason_type_error,setReasonTypeError] = useState("")
    const [remarkerror,setRemarkError] = useState("")
    const [adjust_amount_error,setAdjustAountError] = useState("")
    const [reason_error,setReasonError] = useState("")
    const { websocket } = useContext(WebSocketContext);
    const [reason_type_option,setReasonTypeOption] = useState([])
    const[selectedId,setSelectedId] = useState("")
    const[reasonId,setReasonId] = useState("")
    const Dispatch = useDispatch();
    // const FranchiseUser = FranchiseUserTypeList?.list?.filter((ele) =>ele?.is_acive === true && ele?.id === roomId)
    const [ showModalNew, setShowModalNew ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const resetForm = () => {
        // setSelectedId("")
        // setRemark("");
        // setAdjustType("");
        // setReasonType("");
        // setAdjustAmount("");
        // setReason("");
        // setAdjustTypeError("");
        // setReasonTypeError("");
        // setRemarkError("");
        // setAdjustAountError("");
        // setReasonError("");
    };
    const ParentDealerFranchiseList = AllDealerFranchiseTypeListAll?.list?.filter((elm) => elm?.is_active === true);
    const FranchiseListStateVars = ParentDealerFranchiseList && ParentDealerFranchiseList?.filter((elm) => {
        if(elm?.id === roomId) {
            return elm;
        } else if(elm?.parent_id === roomId) {
            return elm;
        };  
    });

    const GetAvailableBalance = () => {
        const CurrentSelectedFranchaise = ParentDealerFranchiseList?.filter((elm) => elm?.id === roomId)[0];
        const franchiseTypeListAllCurrent = franchiseTypeListAll?.filter((elm) => elm?.id === CurrentSelectedFranchaise?.franchise_type_id)[0];
        let wallet = 0
        
        if(franchiseTypeListAllCurrent?.franchise_type_credit && franchiseTypeListAllCurrent?.franchise_type_credit !== "") {
            wallet = parseInt(franchiseTypeListAllCurrent?.franchise_type_credit);
        } 
        if(CurrentSelectedFranchaise?.credit_addon && CurrentSelectedFranchaise?.credit_addon !== "") {
            wallet = parseInt(CurrentSelectedFranchaise?.credit_addon) + wallet;
        } 
        if(CurrentSelectedFranchaise?.advance_amount && CurrentSelectedFranchaise?.advance_amount !== "") {
            wallet = wallet + parseInt(CurrentSelectedFranchaise?.advance_amount);

        };
        return AmountNumberFormat(wallet);
    }; 
    
    useEffect(() => {
        if (ClearFormSet?.action === 200) {
            // callFilterData()
            Dispatch(ClearFormSetFutios({
                url: "",
                action: false
            }));
        };
    }, [ClearFormSet]);

    const confirmOrderApproval = (e) => {
        const formData = {
            franchise_id: roomId,
            reason_id: reasonId,
            reason_type_id: selectedId,
            reason_type: reason_type?.label,
            reason: reason?.label,
            adj_type: adjust_type?.label,
            adj_amt: adjust_amount,
            remarks: remark.trim()
        };
        // resetForm();
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: "Are you sure?",
            subtitle: "Order Note Submit",
            description: `Are you sure! You want to confirm this transaction?</h5>`,
            modalType: "not form",
            button: "Yes",
            Data: formData
        });
    }
    
    useEffect(()=>{
        if(FranchiseUser.length > 0 && FranchiseUser){
            setFranchiseCode(FranchiseUser[0]?.franchise_code)
            setName(FranchiseUser[0]?.customer_name)
            setAvailableBalance(FranchiseUser[0]?.advance_amount)
        }
        setReasonTypeOption(ReasonTypeList?.filter((elm) => elm?.is_active === true  )?.map((item, index)=>({key:item?.id, value:item?.id , label:item?.reason_type })))
   },[ ReasonTypeList ])
    const data = ReasonList?.filter((elm) => elm?.is_active === true && elm?.reason_type_id === selectedId )?.map((item, index)=>({key:item.id, value:item?.id , label:item?.reason }))
    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        if(adjust_type==="" && adjust_type ===null && adjust_amount ===""  && reason_type ==="" && remark ==="" && reason ===""){
            CheckValid(adjust_type,{type:"adjust_type",error:setAdjustTypeError})
            CheckValid(adjust_amount,{type:"adjust_amount",error:setAdjustAountError})
            CheckValid(reason_type,{type:"reason_type",error:setReasonTypeError})
            CheckValid(remark,{type:"Remark",error:setRemarkError})
            CheckValid(reason,{type:"reason",error:setReasonError})
        }
    };

    const options = [
        { value: 'CREDIT', label: 'CREDIT' },
        { value: 'DEBIT', label: 'DEBIT' },
    ];

//     useEffect(()=>{
//         let param = { 
//             "transmit": "broadcast", 
//             "url": "franchise_list",
//             "request" : { 
//                 "status" : "", 
//                 "limit" : 5000,
//                 "page_no" : 1, 
//                 "search" : ""
//             },
//             "DeviceId" : device_id
//         };
//         wsSend_request(websocket,param)
  
// },[AllDealerFranchiseTypeListAll])


    useEffect(()=>{
        let param = { 
            "transmit": "broadcast", 
            "url": "reason_types_list",
            "request" : { 
                "limit" : 20,
                "page_no" : 1, 
                "search" : "",
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket,param)
    },[device_id,websocket])

    //reason_list
    useEffect(()=>{
        let param = { 
            "transmit": "broadcast", 
            "url": "reason_list",
            "request" : { 
                "limit" : 20,
                "page_no" : 1, 
                "search" : "",
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket,param)

    },[device_id,websocket])

    const handleSelectChange = (selectedOption, fieldName) => {
        if (fieldName === 'adjust_type') {  
            if(selectedOption){
                setAdjustType(selectedOption);
                setAdjustTypeError("");
            }else{
                setAdjustType(null);
                CheckValid(selectedOption,{type:"adjust_type",error:setAdjustTypeError})
            }
        } else if (fieldName === 'reason_type') {
            if(selectedOption){
                setSelectedId(selectedOption?.value)
                setReasonType(selectedOption);
                setReasonTypeError("")
            }else{
                setSelectedId(null)
                setReason(null)
                setReasonType(null);
                CheckValid(selectedOption,{type:"reason_type",error:setReasonTypeError})
            }
        } else if (fieldName === 'reason') {
            if(selectedOption){
                setReasonId(selectedOption?.value)
                setReason(selectedOption);
                setReasonError("")
            }else{
                setReason(null)
                CheckValid(selectedOption,{type:"reason",error:setReasonError})
            }
        }
    };

    return (
        <section className="Main_section">
            <div className="MainHeader">
                <Link to="/debit-credit-notes" className="btn btn-link backbtn" >
                    <i className="bi bi-arrow-left-short"></i> Back
                </Link>
            </div>
            <div className="formloginset" id="formloginset-main">
                <Scrollbars
                    style={{ height: "calc(100vh - 147px)" }}
                    renderView={props => <div {...props} className="view" />}
                    className="ScrollbarsSidebar"
                >
                    <Form onSubmit={handleSubmit} className="ps-5">
                        <Row className="mb-3">
                            <Col sm={2}>
                                <Form.Label className="my-2">Franchise Code</Form.Label>
                            </Col>
                            <Col sm={10}>
                                <Form.Control plaintext readOnly defaultValue={franchise_Code} />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={2}>
                                <Form.Label className="my-2">Name</Form.Label>
                            </Col>
                            <Col sm={10}>
                                <Form.Control plaintext readOnly defaultValue={name} />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={2}>
                                <Form.Label className="my-2">Available Balance</Form.Label>
                            </Col>
                            <Col sm={6}>
                                <Form.Control plaintext readOnly defaultValue={GetAvailableBalance()} />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={2}>
                                <Form.Label className="my-2">Adjust Type<span className="mendatory_star">*</span></Form.Label>
                            </Col>
                            <Col sm={6}>
                            <div className="selectMultiselect" id="selectMultiselectBrand">
                                <Select
                                    value={adjust_type}
                                    onChange={(selectedOption) => handleSelectChange(selectedOption, 'adjust_type')}
                                    isClearable
                                    options={options}
                                    className="godown-select-container"
                                    classNamePrefix="godown-select"
                                    placeholder="Select Adjust Type"
                                    
                                />
                            </div>
                            {adjust_type_error !== "" &&<span className="error_medotory">{adjust_type_error}</span>}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={2}>
                                <Form.Label className="my-2">Amount to Adjust<span className="mendatory_star">*</span></Form.Label>
                            </Col>
                            <Col sm={6}>
                                 <Form.Control 
                                 type="number" 
                                 placeholder="Amount to Adjust" 
                                 className="inputBoxs"
                                 value={adjust_amount} 
                                 onChange={(e) => setAdjustAmount(e.target.value.replace(/^0/, ""))} 
                                 onKeyUp={(e) => CheckValid(e.target.value, {type: 'adjust_amount', error: setAdjustAountError})}
                                 />
                            {adjust_amount_error !== "" &&<span className="error_medotory">{adjust_amount_error}</span>}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={2}>
                                <Form.Label className="my-2">Reason Type<span className="mendatory_star">*</span></Form.Label>
                            </Col>
                            <Col sm={6}>
                            <div className="selectMultiselect" id="selectMultiselectBrand">
                                <Select
                                    value={reason_type}
                                    onChange={(selectedOption) => handleSelectChange(selectedOption, 'reason_type')}
                                    isClearable
                                    options={reason_type_option}
                                    className="godown-select-container"
                                    classNamePrefix="godown-select"
                                    placeholder="Select Reason Type"
                                />
                                </div>
                                {reason_type_error !== "" &&<span className="error_medotory">{reason_type_error}</span>}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={2}>
                                <Form.Label className="my-2">Reason<span className="mendatory_star">*</span></Form.Label>
                            </Col>
                            <Col sm={6}>
                            <div className="selectMultiselect" id="selectMultiselectBrand">
                                <Select
                                    value={reason}
                                    onChange={(selectedOption) => handleSelectChange(selectedOption, 'reason')}
                                    isClearable
                                    options={data}
                                    isDisabled={!reason_type}
                                    className="godown-select-container"
                                    classNamePrefix="godown-select"
                                    placeholder="Select Reason"
                                />
                            </div>
                            {reason_error !== "" &&<span className="error_medotory">{reason_error}</span>}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={2}>
                                <Form.Label className="my-2">Remark<span className="mendatory_star">*</span></Form.Label>
                            </Col>
                            <Col sm={6}>
                                <Form.Control 
                                type="text-area"
                                placeholder="Remark"
                                as='textarea' 
                                onChange={(e) => setRemark(e.target.value)} 
                                onKeyDown={EmptySpaceFieldValid}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'Remark', error: setRemarkError})}
                                />
                             {remarkerror !== "" &&<span className="error_medotory">{remarkerror}</span>}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={{ span: 2, offset: 2 }}>
                                <Button type="submit" id="getDetailButton" className="getDetailButton" onClick={confirmOrderApproval} disabled={!reason || !remark || !adjust_type || !adjust_amount || !reason_type}>
                                    Continue
                                </Button>
                            </Col>
                        </Row>
                        <CommonewModel setShowModalNew={setShowModalNew} showModalNew={showModalNew} />
                    </Form>
                </Scrollbars>
            </div>
        </section>
    );
}

export default DebitCreditNotesDetailPage;
