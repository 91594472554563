const BankDetailVerify = async (api_url, payload, url) => {
    const responce = window.axios.post(api_url, payload, {
    headers: {
      'Authorization': `Bearer ${url === "aadhaar_card_details" ? process.env.REACT_APP_ADHAAR_CARD_API_KEY : process.env.REACT_APP_BANK_ACCOUNT_API_KEY}`
    }}).then(function (result) {
        return {
          ...result.data,
          status:200
        };
    }).catch(function (result) {
        return {
          ...result?.response?.data,
          status:result?.response?.status
        }
    });
    return responce;
};
  
export default BankDetailVerify;