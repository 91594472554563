/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import EmptySpaceFieldValid from "./EmptySpaceFieldValid";
import { API_URL, COURIER_EXPORT_COURIER_URL, STORE_EXPORT_STOCK_ACCESSORIES_URL, WAREHOUSE_EXPORT_DISPATCH_URL, EXPORT_SALES_PERSON_PERFORMANCE_API_URL } from "../Shared/constant";
import { Button, OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";

const DataTableFilter = (props) => {
    const { filterType, searchType, buttonvisible, userSearch, ActiveInactiveFilter, setCurrentPage, ButtonSet, exportVisible, setUserSearch, userType, userLimit, setUserLimit, SelectUserTypeFnct, searchdisable, ParentChildFilter, SelectFranchiseUserTypeFnct, userTeamFilter, setUserTeamFilter, SelectsetWHType, WHType, FilterOpen, setFilterOpen, deviceType, setDeviceType, NormalReturnFlag, setNormalReturnFlag, IsSpares, setIsSpares } = props;
    const [ CommonSearchUpdate, setCommonSearchUpdate ] = useState("");
    const { selfInfo, device_id } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);

const filtertype=props?.filterType
    // export files
    const ExportBtn = () => {
        if(filterType === "Product") {
            window.open(API_URL + "product/export_product", '_blank');
        } else if(filterType === "Product Applications") {
            window.open(API_URL + "product/export_brand", '_blank');
        } else if(filterType === "Category") {
            window.open(API_URL + "product/export_category", '_blank');
        } else if(filterType === "Sub Category"){
            window.open(API_URL + "product/export_sub_category", '_blank');
        } else if(filterType === "Warehouse") {
            window.open(API_URL + "warehouse/export_warehouse", '_blank');
        } else if(filterType === "Frame Number") {
            window.open(API_URL + "store/export_stock_frame", '_blank');
        } else if(filterType === "Region Master") {
            window.open(API_URL + "warehouse/export_region", '_blank');
        } else if(filterType === "States") {
            window.open(API_URL + "warehouse/export_state", '_blank');
        } else if(filterType === "Cities") {
            window.open(API_URL + "warehouse/export_district", '_blank');
        } else if(filterType === "Franchise User Report") {
            window.open(API_URL + "franchise/export_franchise", '_blank');
        } else if(filterType === "Franchise Type") {
            window.open(API_URL + "franchise/export_franchise_type", '_blank');
        } else if(filterType === "Courier") {
            window.open(COURIER_EXPORT_COURIER_URL, '_blank');
        } else if(filterType === "Accessories") {
            window.open(STORE_EXPORT_STOCK_ACCESSORIES_URL, '_blank');
        } else if(filterType === "Dispatch") {
            window.open(WAREHOUSE_EXPORT_DISPATCH_URL, '_blank');
        } else if(filterType === "GSTreport") {
            exportToCSV(filterType);
        } else if(filterType === "SummaryDetails") {
            exportToCSV(filterType);
        } else if(filterType === "Stock Inward Log Details") {
            exportToCSV("StockInwardLogDetails");
        } else if(filterType === "ErpOutstandingReport") {
            exportToCSV("ErpOutstandingReport");
        } else if(filterType === "ErpSummaryDetails") {
            exportToCSV("ErpSummaryDetails");
        } else if(filterType === "salesPersonPerformance") {
            exportToCSV("salesPersonPerformance");
            // window.open(EXPORT_SALES_PERSON_PERFORMANCE_API_URL, '_blank');
        };
    };

    function exportToCSV(filterType) {
        const table = document.getElementById(filterType);
        const rows = table.querySelectorAll("tr");
        let csvContent = "data:text/csv;charset=utf-8,";
        let i = 0;
        rows.forEach((row) => {
          const rowData = [];
          if(i == 0) {
            row.querySelectorAll("th").forEach((cell) => {
                rowData.push(cell.innerText.toString().replace(/,/g, ''));
              });
          } else {
            row.querySelectorAll("td").forEach((cell) => {
                rowData.push(cell.innerText.toString().replace(/,/g, ''));
              });
          }
          i++;
          csvContent += rowData.join(",") + "\n";
        });
      
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", filterType+".csv");
        document.body.appendChild(link); // Required for FF
      
        link.click(); // This will download the CSV file
    };

    const onEnterSearch = (e) => {
        const code = e.which || e.keyCode;
        if(code === 13) {
            setCurrentPage(1);
            OnEnterSearch();
        };
    };

    // search box
    const OnEnterSearch = () => {
        setUserSearch(CommonSearchUpdate);
    };

    // select limit
    const SelectLimitFct = (e) => {
        setUserLimit(parseInt(e.target.value));
        setCurrentPage(1);
    };

    // select team
    const SelectUserTeamTypeFnct = (e) => {
        setUserTeamFilter(e.target.value);
        setCurrentPage(1);
    };

    // clear search box
    const ClearSearchFiled = () => {
        setUserSearch("");
        setCommonSearchUpdate("");
    };

    // clear after delete button hit
    document.addEventListener('keydown', function(event) {
        var keyPressed = event.key;
        if (keyPressed === 'Delete') {
            ClearSearchFiled();
        }
    });

    return(<div className={filterType === "salesPersonPerformance" ? "card-header-New card-header-salesPerson" : "card-header-New"}>
        {searchdisable && (<div className={`searchBoxwithbtn ${userSearch !== ""?"search":""}`}>
            <input 
                type="text" 
                className="form-control" 
                value={CommonSearchUpdate} 
                onKeyDown={(e) => {onEnterSearch(e); EmptySpaceFieldValid(e)}} 
                onChange={(e) => setCommonSearchUpdate(e.target.value)} 
                placeholder={`Search ${(filterType !== "ErpOutstandingReport" && filterType !== "ErpSummaryDetails" && filterType !== "erp_api_failures_delivery_note" && filterType !== "salesPersonPerformance" && filterType !== "ERP_sales_order") ? filterType : ""} ...`}
            />
            <Button className="searchbtn" onClick={() => OnEnterSearch()}>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                    <path d="M21 21l-6 -6"></path>
                </svg>
            </Button>
            {userSearch !== "" && (
                <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 100 }}
                overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                Clear the search by using the delete key.
            </Tooltip>}
            >
                <i className="bi bi-x-circle-fill searchclear" onClick={() => ClearSearchFiled()}></i>
            </OverlayTrigger>)}
            
            <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 100 }}
                overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                Search using {searchType ? `(${searchType})`:""}
            </Tooltip>}
            >
                <i className="searchInfo bi bi-info-circle-fill"></i>
            </OverlayTrigger>
        </div>)}
        <div className="card-header-right">   
            {filterType === "ERP_sales_order" && (<div className="order_bunddle_checkboxes">
                <Form.Label>
                    <Form.Check
                        type="checkbox"
                        checked={IsSpares}
                        onChange={(e) => setIsSpares(!IsSpares)}
                    />
                    Spares Orders
                </Form.Label>
            </div>)}
            {filterType === "Warehouse" && (<select className="form-control activestatusselet" id="FranchiseUserReportWidth" value={WHType} onChange={(e) => {SelectsetWHType(e); setCurrentPage(1);}}>
                <option value="">All</option>
                <option value="E-Bikes, Transportation Charges">E-Bikes</option>
                <option value="Spares, Service, Transportation Charges">Spares</option>
                <option value="Accessories, Transportation Charges">Accessories</option>
                <option value="Marketing, Transportation charges">Marketing</option>
            </select>)}     
            {filterType === "Franchise User Report" && (<select className="form-control activestatusselet" id="FranchiseUserReportWidth" value={ParentChildFilter} onChange={(e) => {SelectFranchiseUserTypeFnct(e); setCurrentPage(1);}}>
                <option value="">All</option>
                <option value="Dealer">Dealer</option>
                <option value="SubDealer">Sub Dealer</option>
            </select>)}
            {filterType === "purchase_list" && (<select className="form-control activestatusselet" id="FranchiseUserReportWidth" value={deviceType} onChange={(e) => {setDeviceType(e.target.value); setCurrentPage(1);}}>
                <option value="">All</option>
                <option value="app">App</option>
                <option value="web">Web</option>
                <option value="customer">Customer</option>
            </select>)}
            {filterType === "erp_api_failures_delivery_note" && (<select className="form-control activestatusselet" id="FranchiseUserReportWidth" value={NormalReturnFlag} onChange={(e) => {setNormalReturnFlag(e.target.value); setCurrentPage(1);}}>
                <option value="normal">Normal</option>
                <option value="return">Return</option>
            </select>)}
            {(props?.orderSource != null && selfInfo?.user?.user_type === 'super_admin') && (
                <select className="form-control activestatusselet pe-4" id="FranchiseUserReportWidthNew" value={props?.orderSource} onChange={(e) => {props?.callSelectSource(e.target.value); setCurrentPage(1);}}>
                    <option value="">All</option>
                    <option value="FO">Dealer</option>
                    <option value="CO">Sale</option>
                    <option value="WO"> Internal Warehouse to Warehouse</option>
                    <option value="AO">Amazon, Website, Flipkart</option>
                </select>
            )}
            {filterType === "Users" && (<select className="form-control selectuserteamDrop" value={userTeamFilter} onChange={(e) => SelectUserTeamTypeFnct(e)}>
                <option value={""}>Search Team</option>
                <option value={"admin"}>Admin</option>
                <option value={"sales_operations"}>Sales Operations</option>
                <option value={"warehouse_manager"}>Warehouse Manager</option>
                <option value={"inventory_operations"}>Inventory Operations</option>
                <option value={"logistics_ops"}>Logistics Ops</option>
                <option value={"accounts"}>Accounts</option>
                <option value={"regional_sales_team"}>Regional Sales Team</option>
                <option value={"inside_sales"}>Inside Sales</option>
                <option value={"ecommerce"}>Ecommerce</option>
                <option value={"sale_franchise_person"}>Sale Franchise Person</option>
            </select>)}
            {ActiveInactiveFilter && (<select className="form-control activestatusselet" value={userType} onChange={(e) => SelectUserTypeFnct(e)}>
                <option value="all">All</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
            </select>)}
            {(filterType === "Product" || filterType === "Payment-Approval" || filterType === "debit-credit-note-report" || filterType === "GSTreport" || filterType === "Account Ledger Report") ? (<select className="form-control userLimitselect" value={userLimit} onChange={(e) => SelectLimitFct(e)}>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
                <option value="60">60</option>
                <option value="70">70</option>
                <option value="80">80</option>
                <option value="90">90</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
            </select>) : (<select className="form-control userLimitselect" value={userLimit} onChange={(e) => SelectLimitFct(e)}>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
                <option value="60">60</option>
                <option value="70">70</option>
                <option value="80">80</option>
                <option value="90">90</option>
                <option value="100">100</option>
            </select>)}
            {(filterType !== "Payment-Approval" && filterType !== "debit-credit-note-report" && filterType !== "Account Ledger Report" && filterType !== "Users" && filterType !== "Pincodes" && filterType !== "EwayBill" && filterType !== "") && (<button className="btn exportbtn" onClick={() => ExportBtn()} >
                <i className="bi bi-filetype-xlsx"></i>
                Export
            </button>)}
            {buttonvisible && (<button className="btn head_cmn_btn" style={{ marginLeft: filterType === "Users" ? "10px" : "0px" }} onClick={ButtonSet?.function}>
                <i className={ButtonSet?.buttontitle === "Return & Replace" ? "bi bi-arrow-return-right" : "bi bi-plus-circle"}></i>
                {ButtonSet?.buttontitle}
            </button>)}
            {filterType === "Franchise User Report" && (<OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 100 }}
                overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                    {FilterOpen ? "Filter Hide" : "Filter Show"}
                </Tooltip>}
            >
                <button
                    className="filtercollapsebtn"
                    style={{ marginLeft: "10px" }}
                    onClick={() => setFilterOpen(!FilterOpen)}
                    aria-controls="example-collapse-text"
                    aria-expanded={FilterOpen}
                >
                    {FilterOpen ? (<i className="bi bi-funnel-fill"></i>) : (<i className="bi bi-funnel"></i>)}
                </button>
            </OverlayTrigger>)}
        </div>
    </div>)
}
DataTableFilter.propTypes = {
    orderSource: PropTypes.any,
    callSelectSource: PropTypes.func,
}
DataTableFilter.defaultProps = {
    orderSource: null,
    callSelectSource: ()=>{},
}
export default DataTableFilter;