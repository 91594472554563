/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Badge } from 'react-bootstrap';
import DataTableFilter from "../../Common/DataTableFilter";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useNavigate } from "react-router-dom";
import { CREATE_CATEGORY_URL } from "../../Shared/constant";
import { WebSocketContext } from "../../../App";
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonPagination from "../../Common/CommonPagination";
import moment from "moment";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";

const SparesCampatibiity = (props) => {
    const { RouteName } = props;
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const { sparescampatibiityList, device_id, selfInfo } = useSelector((state) => state.adminReducers);
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const { websocket } = useContext(WebSocketContext);
    const PermissionsAccess = selfInfo?.user?.permissions_access;
    
    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };
    
    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // api calling
    const APICall = () => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ProductMasters[5]?.Edit || PermissionsAccess?.ProductMasters[5]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "spare_item_list",
                "request" : { 
                    "limit" : userLimit,
                    "page_no" : currentPage, 
                    "search" : userSearch.trim(),
                    "order_by": "-updated_at"
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket, userSearch, userType, currentPage, userLimit ]);

    // create applications
    const cmnFnct = () => {
        Navigate(CREATE_CATEGORY_URL);
    };

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
    }, []);

    // total records and list length check
    const TotalRecords = parseInt(sparescampatibiityList?.pagination?.total_records);

    return(<section className="Main_section">
        <DataTableFilter 
            filterType={""}
            searchType={"Primary Item Code, Primary Item Name, Campatible Item Code, Campatible Item Name"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={false}
            ButtonSet={{
                buttontitle: "Create Category",
                function: cmnFnct
            }}
            searchdisable={true}
            ActiveInactiveFilter={false}
        />
        <div className="tableView gstreporttableadded">
            <Scrollbars 
                style={{ height: (sparescampatibiityList?.pagination?.total_records > 10 && sparescampatibiityList?.list?.length !== TotalRecords) ? "calc(100vh - 185px)" : "calc(100vh - 150px)"}} 
                renderView={props => <div {...props} className="view"/>}
                className="ScrollbarsSidebar"
            >
                <Table bordered  >
                    <thead>
                        <tr>
                            <th>SNO</th>
                            <th>Primary Item Code</th>
                            <th>Primary Item Name</th>
                            <th>Campatible Item Code</th>
                            <th>Campatible Item Name</th>
                            <th>Category</th>
                            <th>Created At</th>
                            <th>Primary Item Status</th>
                            <th>Campatible Item Status</th>
                            {/* {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.ProductMasters[5]?.Edit) && (<th>Action</th>)} */}
                        </tr>
                    </thead>
                    <tbody>
                        {sparescampatibiityList?.list?.map((elm, index) => {
                            return(<tr key={index}>
                                <td>
                                    {GetCurrentTableSRNo(index, sparescampatibiityList?.pagination?.current_page, sparescampatibiityList?.pagination?.record_limit)}
                                </td>
                                <td>{elm?.primary_item_code}</td>
                                <td>{elm?.primary_item_name}</td>
                                <td>{elm?.compatible_item_code}</td>
                                <td style={{ whiteSpace: "pre" }} dangerouslySetInnerHTML={{__html: elm?.compatible_item_name }}></td>
                                <td>{elm?.type}</td>
                                <td>{moment(elm.created_at).format('LL')}</td>
                                <td>
                                    {elm?.primary_item_status ? <Badge bg="success">Active</Badge> : <Badge bg="danger">Inactive</Badge>}
                                </td>
                                <td>
                                    {elm?.compatible_item_status ? <Badge bg="success">Active</Badge> : <Badge bg="danger">Inactive</Badge>}
                                </td>
                                {/* {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.ProductMasters[1]?.Edit) && (<td>
                                    <Link className="btn edittable" to={`/edit-category/${elm?.id}`}>
                                        <i className="bi bi-pencil-square"></i>
                                    </Link>
                                </td>)}  */}
                            </tr>)
                        })}
                        {sparescampatibiityList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="9">Spares compatibility list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {/* pagination */}
            {sparescampatibiityList?.pagination?.total_records > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={sparescampatibiityList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
    </section>)
}

export default SparesCampatibiity;