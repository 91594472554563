/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Badge, Button, Form, Image, Spinner } from 'react-bootstrap';
import CommonToggle from "../../Common/CommonToggle";
import { Link, useNavigate, useLocation, useParams  } from "react-router-dom";
import { PRODUCT_APPLICATIONS_URL, FILE_AUTH_URL } from "../../Shared/constant";
import DefaultImage from "../../../assets/img/DefaultProduct.jpg";
import UploadImageIcon from "../../../assets/img/uploadImage.png";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import CheckValid from "../../Common/CheckValid";
import UploadFileAPI from "../../../Api/UploadFileAPI";
import { useSelector, useDispatch } from "react-redux";
import LoaderImage from "../../../assets/img/spinner_transferent.svg";
import FileAuthConvert from "../../../Api/FileAuthConvert";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";

const CreateApplications = () => {
    const Dispatch = useDispatch();
    const Navigate = useNavigate();
    const LocationRoute = useLocation()?.pathname?.split("/")[1];
    const { websocket } = useContext(WebSocketContext);
    const [ UploadFileLoader, setUploadFileLoader ] = useState(false);
    const [ ApplicationState, setApplicationState ] = useState({
        BrandImage: "",
        BrandName: "",
        Status: true
    });
    const [ ImageDisplay, setImageDisplay ] = useState("");
    const { roomId } = useParams();
    const { accessToken, ClearFormSet, brandList, device_id } = useSelector((state) => state.adminReducers);
    const CurrentData_ = brandList?.list?.filter((elm) => elm?.id === roomId)[0];

    // error state
    const [ errorBrandName, seterrorBrandName ] = useState("");

    useEffect(() => {
        if(LocationRoute === "edit-product-application") {
            setApplicationState({
                ...ApplicationState,
                BrandImage: CurrentData_?.brand_image,
                BrandName: CurrentData_?.brand_name,
                Status: CurrentData_?.is_active
            });
            setImageDisplay(FILE_AUTH_URL + CurrentData_?.view_thumbnail_url);
        };
    }, [ CurrentData_ ])

    // upload product image 
    const UploadImage = async (e) => {
        setUploadFileLoader(true);
        const files = e.target.files[0];
        const formData = new FormData();
        formData.append("file", files, files.name);
        const fileresponse = await UploadFileAPI(accessToken, formData);
        if(fileresponse?.status === 200) {
            setApplicationState({...ApplicationState, BrandImage: fileresponse?.id});
            const Authfileresponse = await FileAuthConvert(accessToken, FILE_AUTH_URL + fileresponse?.view_thumbnail_url);
            if(Authfileresponse?.status === 200) {
                setUploadFileLoader(false);
                setImageDisplay(Authfileresponse?.authImage);
            } else {
                setUploadFileLoader(false);
            };
        } else {
            setUploadFileLoader(false);
        };
    };

    // create and edit brand
    const CreateBrand = () => {
        if(ApplicationState?.BrandName.trim() !== "") {
            if(LocationRoute === "edit-product-application") {
                Dispatch(ClearFormSetFutios({
                    url: "brand_edit",
                    action: true
                }));
                let param = { 
                    "transmit": "broadcast", 
                    "url": "brand_edit",
                    "request" : { 
                        "brand_id" : CurrentData_?.id,
                        "brand_name" : ApplicationState?.BrandName.trim(), 
                        "brand_image" : ApplicationState?.BrandImage,
                        "is_active" : ApplicationState?.Status
                    },
                    "DeviceId" : device_id
                };
                wsSend_request(websocket, param);
            } else {
                Dispatch(ClearFormSetFutios({
                    url: "brand_add",
                    action: true
                }));
                let param = { 
                    "transmit": "broadcast", 
                    "url": "brand_add",
                    "request" : { 
                        "brand_name" : ApplicationState?.BrandName.trim(), 
                        "brand_image" : ApplicationState?.BrandImage,
                        "is_active" : ApplicationState?.Status
                    },
                    "DeviceId" : device_id
                };
                wsSend_request(websocket, param);
            }
        } else {
            CheckValid(ApplicationState?.BrandName.trim(), {type: 'BrandName', error: seterrorBrandName});
        };
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200 && (ClearFormSet?.url === "brand_add" || ClearFormSet?.url === "brand_edit")) {
            Navigate(PRODUCT_APPLICATIONS_URL);
        };
    }, [ ClearFormSet ]);

    // remove image uploaded
    const RemoveUploadImage = () => {
        setApplicationState({...ApplicationState, BrandImage: ""});
        setImageDisplay("");
    };

    return(<section className="createApplcation_wrapper">
        <div className="MainHeader">
            <Link to={PRODUCT_APPLICATIONS_URL} className="btn btn-link backbtn"><i className="bi bi-arrow-left-short"></i> Back</Link>
        </div>
        <div className="tabsWrappers">
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-12">
                        <Form.Group className="mb-3" >
                            <Form.Label>Brand Image</Form.Label>
                            {(ImageDisplay !== undefined && ImageDisplay !== "") ? (<div className="imageuploads">
                                <Image src={ImageDisplay} onError={(e) => e.target.src = DefaultImage} alt="charity avatar" />
                                <span className="closeIcon" onClick={() => RemoveUploadImage()}></span>
                            </div>) : (<label className="uploadbuttons">
                                <input type="file" className="uploadfilInput" id="fileInput" onChange={(e) => UploadImage(e)} disabled={UploadFileLoader} accept=".png, .jpg, .jpeg"/>
                                <div className={UploadFileLoader ? "fileloader active" : "fileloader"}>
                                        <Image src={UploadFileLoader ? LoaderImage : UploadImageIcon} className="uploadImgIconse" alt="upload image" />
                                </div>
                                <div className="uploadfilebox">
                                    <Image src={DefaultImage} alt="upload image" />
                                </div>
                            </label>)}
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Brand Name<span className="mendatory_star">*</span></Form.Label>
                            <Form.Control 
                                type="text" 
                                value={ApplicationState?.BrandName} 
                                onChange={(e) => setApplicationState({...ApplicationState, BrandName: e.target.value})}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'BrandName', error: seterrorBrandName})}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter Brand Name" 
                                disabled={UploadFileLoader}
                            />
                            {errorBrandName !== "" &&<span className="error_medotory">{errorBrandName}</span>}
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Status</Form.Label>
                            <div className="ToggleMainWrap">
                                <CommonToggle 
                                    valueToggle={ApplicationState?.Status}
                                    setValueToggle={() => setApplicationState({...ApplicationState, Status: !ApplicationState?.Status})}
                                    name={""}
                                />
                                {ApplicationState?.Status ? <Badge bg="success">Active</Badge> : <Badge bg="danger">Inactive</Badge>}
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Button 
                            disabled={ClearFormSet?.action === true && (ClearFormSet?.url === "brand_add" || ClearFormSet?.url === "brand_edit")}
                            className="formbtn widthfullbtn" 
                            onClick={() => CreateBrand()} 
                            variant="primary" 
                        >
                        {LocationRoute === "edit-product-application" ? "Update" : "Add"}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}

export default CreateApplications;