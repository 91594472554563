export const ActionTypes = {
    IS_LOGIN: "IS_LOGIN",
    GET_USER_ID: "GET_USER_ID",
    GET_DEVICE_ID: "GET_DEVICE_ID",
    SELF_DETAILS: "SELF_DETAILS",
    MAIN_NAVIGATION: "MAIN_NAVIGATION",
    GET_ACCESSTOKEN: "GET_ACCESSTOKEN",
    CLEAR_FORM_SET_FUCT: "CLEAR_FORM_SET_FUCT",
    SET_STORE_OPTIONS_LIST: "SET_STORE_OPTIONS_LIST",
    SET_STORE_OPTIONS_LIST_CLEAR: "SET_STORE_OPTIONS_LIST_CLEAR",
    GET_BRAND_LIST: "GET_BRAND_LIST",
    IMAGE_STORED_REDUX: "IMAGE_STORED_REDUX",
    ADD_NEW_BRAND_LIST: "ADD_NEW_BRAND_LIST",
    EDIT_BRAND_LIST: "EDIT_BRAND_LIST",
    GET_ALL_BRANDS_LIST: "GET_ALL_BRANDS_LIST",
    GET_CATEGORY_LIST: "GET_CATEGORY_LIST",
    ADD_CATEGORY_LIST: "ADD_CATEGORY_LIST",
    UPDATE_CATEGORY_LIST: "UPDATE_CATEGORY_LIST",
    ACTIVE_LIST_ITEM_STATUS: "ACTIVE_LIST_ITEM_STATUS",
    ADMIN_LOGOUT: "ADMIN_LOGOUT",
    SET_MAGIC_CODE: "SET_MAGIC_CODE",
    GET_SUB_CATEGORY_LIST: "GET_SUB_CATEGORY_LIST",
    ADD_SUB_CATEGORY_LIST: "ADD_SUB_CATEGORY_LIST",
    UPDATE_SUB_CATEGORY_LIST: "UPDATE_SUB_CATEGORY_LIST",
    GET_ALL_CATEGORY_LIST: "GET_ALL_CATEGORY_LIST",
    GET_SPARES_CAMPATIBILITY_LIST: "GET_SPARES_CAMPATIBILITY_LIST",
    GET_ALL_SPARES_CAMPATIBILITY_LIST: "GET_ALL_SPARES_CAMPATIBILITY_LIST",
    GET_PRODUCT_LIST: "GET_PRODUCT_LIST",
    GET_BUNDLES_LIST_ALL: "GET_BUNDLES_LIST_ALL",
    ADD_PRODUCT_LIST: "ADD_PRODUCT_LIST",
    UPDATE_PRODUCT_LIST: "UPDATE_PRODUCT_LIST",
    GET_ALL_PRODUCT_LIST: "GET_ALL_PRODUCT_LIST",
    GET_ALL_PRODUCT_CATEGORY_LIST: "GET_ALL_PRODUCT_CATEGORY_LIST",
    GET_ALL_SUB_CATEGORY_LIST: "GET_ALL_SUB_CATEGORY_LIST",
    GET_CITY_LIST: "GET_CITY_LIST",
    ADD_CITY_LIST: "ADD_CITY_LIST",
    UPDATE_CITY_LIST: "UPDATE_CITY_LIST",
    GET_STATE_LIST: "GET_STATE_LIST",
    ADD_STATE_LIST: "ADD_STATE_LIST",
    GET_ALL_STATE_LIST: "GET_ALL_STATE_LIST",
    UPDATE_STATE_LIST: "UPDATE_STATE_LIST",
    GET_PIN_CODE_LIST: "GET_PIN_CODE_LIST",
    GET_ALL_CITY_LIST: "GET_ALL_CITY_LIST",
    GET_REGION_MASTER_LIST: "GET_REGION_MASTER_LIST",
    ADD_REGION_MASTER_LIST: "ADD_REGION_MASTER_LIST",
    UPDATE_REGION_MASTER_LIST: "UPDATE_REGION_MASTER_LIST",
    GET_USER_LIST: "GET_USER_LIST",
    GET_ALL_USER_LIST: "GET_ALL_USER_LIST",
    GET_ALL_REGION_LIST: "GET_ALL_REGION_LIST",
    ADD_USER_LIST: "ADD_USER_LIST",
    UPDATE_USER_LIST: "UPDATE_USER_LIST",
    GET_WAREHOUSE_LIST: "GET_WAREHOUSE_LIST",
    PINCODES_SEARCH_LIST: "PINCODES_SEARCH_LIST",
    COMMON_PINCODES_SEARCH_LIST: "COMMON_PINCODES_SEARCH_LIST",
    COMMON_LOADER: "COMMON_LOADER",
    COMMON_MAIN_LOADER: "COMMON_MAIN_LOADER",
    ERROR_MESSAGE_FROM_REDUX: "ERROR_MESSAGE_FROM_REDUX",
    ERROR_FRAMES_MESSAGE_FROM_REDUX: "ERROR_FRAMES_MESSAGE_FROM_REDUX",
    ADD_WAREHOUSE_LIST: "ADD_WAREHOUSE_LIST",
    UPDATE_WAREHOUSE_LIST: "UPDATE_WAREHOUSE_LIST",
    GET_ALL_WAREHOUSE_LIST: "GET_ALL_WAREHOUSE_LIST",
    STORED_DETAIL_REPORT_LIST: "STORED_DETAIL_REPORT_LIST",
    STORED_DETAIL_LOG_REPORT_LIST: "STORED_DETAIL_LOG_REPORT_LIST",
    SET_STORE_ACCESSORIES_REPORT: "SET_STORE_ACCESSORIES_REPORT",
    STORED_LEVEL_REPORT_LIST: "STORED_LEVEL_REPORT_LIST",
    STORED_LEVEL_ALL_REPORT_LIST: "STORED_LEVEL_ALL_REPORT_LIST",
    SO_APPROVAL_STORED_LEVEL_REPORT_LIST: "SO_APPROVAL_STORED_LEVEL_REPORT_LIST",
    FRANCHISE_TYPE_LIST: "FRANCHISE_TYPE_LIST",
    ADD_FRANCHISE_TYPE_LIST: "ADD_FRANCHISE_TYPE_LIST",
    UPDATE_FRANCHISE_TYPE_LIST: "UPDATE_FRANCHISE_TYPE_LIST",
    GET_ALL_FRANCHISE_TYPE_LIST_LIST: "GET_ALL_FRANCHISE_TYPE_LIST_LIST",
    GET_FRANCHISE_USER_TYPE_LIST: "GET_FRANCHISE_USER_TYPE_LIST",
    ADD_FRANCHISE_USER_TYPE_LIST: "ADD_FRANCHISE_USER_TYPE_LIST",
    UPDATE_FRANCHISE_USER_TYPE_LIST: "UPDATE_FRANCHISE_USER_TYPE_LIST",
    GET_ALL_DEALER_FRANCHISE_USER_TYPE_LIST: "GET_ALL_DEALER_FRANCHISE_USER_TYPE_LIST",
    GET_PRODUCT_GROUP_LIST: "GET_PRODUCT_GROUP_LIST",
    ADD_PRODUCT_GROUP_LIST: "ADD_PRODUCT_GROUP_LIST",
    UPDATE_PRODUCT_GROUP_LIST: "UPDATE_PRODUCT_GROUP_LIST",
    GET_ALL_PRODUCT_GROUP_LIST: "GET_ALL_PRODUCT_GROUP_LIST",
    FRANCHISE_ORDER_REPORT_LIST: "FRANCHISE_ORDER_REPORT_LIST",
    ORDER_DETAILS_REPORT: "ORDER_DETAILS_REPORT",
    GET_AFTER_SALES_ORDER_DETAILS_REPORT: "GET_AFTER_SALES_ORDER_DETAILS_REPORT",
    ORDER_DETAILS_BY_ORDER_CODE: "ORDER_DETAILS_BY_ORDER_CODE",
    SET_STORE_ACCESSORIES_LIST: "SET_STORE_ACCESSORIES_LIST",
    ORDER_PO_APPROVALS_ORDERS: "ORDER_PO_APPROVALS_ORDERS",
    ORDER_WAREHOUSE_APPROVALS_ORDERS: "ORDER_WAREHOUSE_APPROVALS_ORDERS",
    PENDING_FOR_ACCOUNT_ORDERS_LIST: "PENDING_FOR_ACCOUNT_ORDERS_LIST",
    PENDING_FOR_STORES_ORDERS_LIST: "PENDING_FOR_STORES_ORDERS_LIST",
    DISPATCH_LIST: "DISPATCH_LIST",
    ADD_DISPATCH_LIST: "ADD_DISPATCH_LIST",
    UPDATE_DISPATCH_LIST: "UPDATE_DISPATCH_LIST",
    GET_ALL_DISPATCH_LIST: "GET_ALL_DISPATCH_LIST",
    SET_STORE_COURIERS_LIST: "SET_STORE_COURIERS_LIST",
    SET_RELATED_COURIERS_LIST: "SET_RELATED_COURIERS_LIST",
    SET_STORE_PENDING_DISPATCH_LIST: "SET_STORE_PENDING_DISPATCH_LIST",
    SET_STORE_PENDING_DELIVERY_LIST: "SET_STORE_PENDING_DELIVERY_LIST",
    SET_STORE_OPTION_COURIERS_LIST: "SET_STORE_OPTION_COURIERS_LIST",
    SET_STORE_OPTION_COURIERS_NEW_LIST: "SET_STORE_OPTION_COURIERS_NEW_LIST",
    SET_STORE_BEFORE_LOADMORE_OPTION_COURIERS_NEW_LIST: "SET_STORE_BEFORE_LOADMORE_OPTION_COURIERS_NEW_LIST",
    SET_STORE_DELIVERY_LIST: "SET_STORE_DELIVERY_LIST",
    SET_STORE_REJECTED_ORDER_LIST: "SET_STORE_REJECTED_ORDER_LIST",
    SET_STORE_MODAL_INVOICE_DETAILS: "SET_STORE_MODAL_INVOICE_DETAILS",
    SET_STORE_CANCELLED_ORDER_LIST: "SET_STORE_CANCELLED_ORDER_LIST",
    SET_STORE_INVOICE_LIST: "SET_STORE_INVOICE_LIST",
    SET_STORE_EINVOICE_SUCCESS_LIST: "SET_STORE_EINVOICE_SUCCESS_LIST",
    SET_STORE_EINVOICE_FAILURE_LIST: "SET_STORE_EINVOICE_FAILURE_LIST",
    GET_STOCK_INWARD_LIST: "GET_STOCK_INWARD_LIST",
    GET_BOX_LIST: "GET_BOX_LIST",
    GET_ALL_BOX_LIST: "GET_ALL_BOX_LIST",
    ADD_BOX_LIST: "ADD_BOX_LIST",
    UPDATE_BOX_LIST: "UPDATE_BOX_LIST",
    GET_ALL_REASON_TYPE_LIST: "GET_ALL_REASON_TYPE_LIST",
    GET_ALL_REASON_LIST: "GET_ALL_REASON_LIST",
    ADD_DEBIT_CREDIT_NOTE: "ADD_DEBIT_CREDIT_NOTE",
    GET_ALL_CREDIT_DEBIT_LIST: "GET_ALL_CREDIT_DEBIT_LIST",
    GET_RETURN_REPLACE_ORDER_LIST: "GET_RETURN_REPLACE_ORDER_LIST",
    GET_RETURN_REPLACE_SINGLE_ORDER: "GET_RETURN_REPLACE_SINGLE_ORDER",
    GET_RETURN_REPLACE_INVOICE_ORDER_DETAILS: "GET_RETURN_REPLACE_INVOICE_ORDER_DETAILS",
    GET_RETURN_REPLACE_FRAMES_ORDER_DETAILS: "GET_RETURN_REPLACE_FRAMES_ORDER_DETAILS",
    GET_DEBIT_CREDIT_NOTE_REPORT_LIST: "GET_DEBIT_CREDIT_NOTE_REPORT_LIST",
    GET_PAYMENT_APPROVAL_LIST_LIST: "GET_PAYMENT_APPROVAL_LIST_LIST",
    GET_DNCN_LEDGER_LIST: "GET_DNCN_LEDGER_LIST",
    ADD_PAYMENT_LIST: "ADD_PAYMENT_LIST",
    GET_GST_REPORT_LIST: "GET_GST_REPORT_LIST",
    TABLE_SORTING_BY: "TABLE_SORTING_BY",
    PURCHASE_LIST: "PURCHASE_LIST",
    GET_ALL_FRAME_LIST: "GET_ALL_FRAME_LIST",
    UPDATE_PURCHASE_LIST: "UPDATE_PURCHASE_LIST",
    GET_ORDER_LOG_LIST :"GET_ORDER_LOG_LIST",
    GET_INWARD_LOG_LIST: "GET_INWARD_LOG_LIST",
    GET_WAREHOUSE_WITH_PINCODE_LIST: "GET_WAREHOUSE_WITH_PINCODE_LIST",
    ADD_FRANCHISE_BRAND_LIST:"ADD_FRANCHISE_BRAND_LIST",
    REMOVE_FRANCHISE_BRAND:"REMOVE_FRANCHISE_BRAND",
    UPDATE_ORDER_MANAGEMENT_FILTER:"UPDATE_ORDER_MANAGEMENT_FILTER",
    FRANCHISE_ORDER_LATEST_THREE_LIST:"FRANCHISE_ORDER_LATEST_THREE_LIST",
    POINT_VALUE_LIST: "POINT_VALUE_LIST",
    ADD_POINT_VALUE_LIST: "ADD_POINT_VALUE_LIST",
    UPDATE_POINT_VALUE_LIST: "UPDATE_POINT_VALUE_LIST",
    GET_REFURBISHED_SETTINGS: "GET_REFURBISHED_SETTINGS",
    GET_ERP_ACCOUNT_LIST_LIST:"GET_ERP_ACCOUNT_LIST_LIST",
    GET_ERP_ACCOUNT_LEADGER_LIST:"GET_ERP_ACCOUNT_LEADGER_LIST",
    GET_ERP_GENERAL_LEADGER_LIST: "GET_ERP_GENERAL_LEADGER_LIST",
    GET_RESELLCYCLE_REPORT_LIST:"GET_RESELLCYCLE_REPORT_LIST",
    GET_RESELLCYCLE_CUSTOMER_REPORT_LIST: "GET_RESELLCYCLE_CUSTOMER_REPORT_LIST",
    UPDATE_REFURBISHED_SETTINGS:"UPDATE_REFURBISHED_SETTINGS",
    GET_NAVIGATIONS_POINT_LIST:"GET_NAVIGATIONS_POINT_LIST",
    GET_POINTS_SETTINGS_LIST:"GET_POINTS_SETTINGS_LIST",
    ADD_POINTS_SETTINGS_LIST:"ADD_POINTS_SETTINGS_LIST",
    UPDATE_POINTS_SETTINGS_LIST:"UPDATE_POINTS_SETTINGS_LIST",
    DELETE_POINTS_SETTINGS_LIST:"DELETE_POINTS_SETTINGS_LIST",
    GET_AGE_VALUE_LIST:"GET_AGE_VALUE_LIST",
    ADD_AGE_VALUE_LIST:"ADD_AGE_VALUE_LIST",
    UPDATE_AGE_VALUE_LIST:"UPDATE_AGE_VALUE_LIST",
    DELETE_AGE_VALUE:"DELETE_AGE_VALUE",
    GET_RESELL_BRAND_LIST:"GET_RESELL_BRAND_LIST",
    ADD_RESELL_BRAND:"ADD_RESELL_BRAND",
    UPDATE_RESELL_BRAND:"UPDATE_RESELL_BRAND",
    GET_ERP_API_FAILURE_LIST:"GET_ERP_API_FAILURE_LIST",
    GET_ERP_INVOICE_API_FAILURE_LIST: "GET_ERP_INVOICE_API_FAILURE_LIST",
    GET_ERP_DELIVERY_NOTE_FRAME_NUMBER_FAILURE_LIST: "GET_ERP_DELIVERY_NOTE_FRAME_NUMBER_FAILURE_LIST",
    GET_ERP_PURCHASE_TRUE_LIST: "GET_ERP_PURCHASE_TRUE_LIST", 
    GET_ERP_PURCHASE_FALSE_LIST: "GET_ERP_PURCHASE_FALSE_LIST",
    GET_SEARCH_MODULES_OBJECT: "GET_SEARCH_MODULES_OBJECT",
    GET_FBA_ORDER_LIST: "GET_FBA_ORDER_LIST",
    GET_SALES_PERSON_PERFORMANCE_LIST: "GET_SALES_PERSON_PERFORMANCE_LIST",
    GET_ALL_SALES_PERSON_PERFORMANCE_LIST: "GET_ALL_SALES_PERSON_PERFORMANCE_LIST",
    GET_DARK_LIGHT_MODE: "GET_DARK_LIGHT_MODE",
    GET_INCENTIVE_SLAB_LIST: "GET_INCENTIVE_SLAB_LIST",
    ADD_INCENTIVE_SLAB_LIST: "ADD_INCENTIVE_SLAB_LIST",
    UPDATE_INCENTIVE_SLAB_LIST: "UPDATE_INCENTIVE_SLAB_LIST",
    GET_ORDER_CANCEL_STATUS: "GET_ORDER_CANCEL_STATUS",
    GET_AFTER_SELES_ORDERS_LIST: "GET_AFTER_SELES_ORDERS_LIST",
    GET_FRANCHISE_SCHEME_LIST: "GET_FRANCHISE_SCHEME_LIST",
    ADD_FRANCHISE_SCHEME_LIST: "ADD_FRANCHISE_SCHEME_LIST", 
    UPDATE_FRANCHISE_SCHEME_LIST: "UPDATE_FRANCHISE_SCHEME_LIST",
    GET_UNICOMMERCE_INVOICE_FAILURE_LIST: "GET_UNICOMMERCE_INVOICE_FAILURE_LIST",
    GET_UNICOMMERCE_SHIPMENT_FAILURE_LIST: "GET_UNICOMMERCE_SHIPMENT_FAILURE_LIST",
    GET_UNICOMMERCE_TRACK_STATUS_FAILURE_LIST: "GET_UNICOMMERCE_TRACK_STATUS_FAILURE_LIST",
    GET_ALL_FRANCHISE_SCHEME_LIST: "GET_ALL_FRANCHISE_SCHEME_LIST",
    GET_TICKETS_LIST: "GET_TICKETS_LIST"
}

export const checkLogin = (status) => {
    return {
        type: ActionTypes.IS_LOGIN,
        payload: status,
    }
}

export const setStoreCouriersList = (status) => {
    return {
        type: ActionTypes.SET_STORE_COURIERS_LIST,
        payload: status,
    }
}

export const getRelatedCourierList = (data) => {
    return {
        type: ActionTypes.SET_RELATED_COURIERS_LIST,
        payload: data
    }
}

export const setStoreInvoiceList = (status) => {
    return {
        type: ActionTypes.SET_STORE_INVOICE_LIST,
        payload: status,
    }
}
export const setStoreEInvoiceSuccessList = (status) => {
    return {
        type: ActionTypes.SET_STORE_EINVOICE_SUCCESS_LIST,
        payload: status,
    }
}
export const setStoreEInvoiceFailureList = (status) => {
    return {
        type: ActionTypes.SET_STORE_EINVOICE_FAILURE_LIST,
        payload: status,
    }
}
export const setStoreOptionCouriersList = (status) => {
    return {
        type: ActionTypes.SET_STORE_OPTION_COURIERS_LIST,
        payload: status,
    }
}

export const setStoreOptionCouriersNewList = (status) => {
    return {
        type: ActionTypes.SET_STORE_OPTION_COURIERS_NEW_LIST,
        payload: status,
    }
}

export const setStoreOptionBeforeLoadCouriersNewList = (status) => {
    return {
        type: ActionTypes.SET_STORE_BEFORE_LOADMORE_OPTION_COURIERS_NEW_LIST,
        payload: status,
    }
}

export const setStorePendingDispatchList = (status) => {
    return {
        type: ActionTypes.SET_STORE_PENDING_DISPATCH_LIST,
        payload: status,
    }
}
export const setStorePendingDeliveryList = (status) => {
    return {
        type: ActionTypes.SET_STORE_PENDING_DELIVERY_LIST,
        payload: status,
    }
}
export const setStoreDeliveredList = (status) => {
    return {
        type: ActionTypes.SET_STORE_DELIVERY_LIST,
        payload: status,
    }
}
export const setStoreRejectedOrderList = (status) => {
    return {
        type: ActionTypes.SET_STORE_REJECTED_ORDER_LIST,
        payload: status,
    }
}
export const setStoreCancelledOrderList = (status) => {
    return {
        type: ActionTypes.SET_STORE_CANCELLED_ORDER_LIST,
        payload: status,
    }
}

export const getUserId = (id) => {
    return {
        type: ActionTypes.GET_USER_ID,
        payload: id,
    }
}

export const getDeviceId = (id) => {
    return {
        type: ActionTypes.GET_DEVICE_ID,
        payload: id,
    }
}

export const getSelfDetails = (user) => {
    return {
        type: ActionTypes.SELF_DETAILS,
        payload: user,
    }
}

export const SelectMainNavigation = (url) => {
    return {
        type: ActionTypes.MAIN_NAVIGATION,
        payload: url,
    }
}


export const getAccessToken = (token) => {
    return {
        type: ActionTypes.GET_ACCESSTOKEN,
        payload: token,
    }
}

export const ClearFormSetFutios = (date_) => {
    return {
        type: ActionTypes.CLEAR_FORM_SET_FUCT,
        payload: date_,
    }
}
export const setStoreOptionList = (date_) => {
    return {
        type: ActionTypes.SET_STORE_OPTIONS_LIST,
        payload: date_,
    }
}

export const setStoreOptionListClear = (date_) => {
    return {
        type: ActionTypes.SET_STORE_OPTIONS_LIST_CLEAR,
        payload: date_,
    }
}

export const getBrandList = (date_) => {
    return {
        type: ActionTypes.GET_BRAND_LIST,
        payload: date_,
    }
}

export const addNewBrandList = (date_) => {
    return {
        type: ActionTypes.ADD_NEW_BRAND_LIST,
        payload: date_,
    }
}

export const editBrandList = (date_) => {
    return {
        type: ActionTypes.EDIT_BRAND_LIST,
        payload: date_,
    }
}

export const imageStoredRedux = (date_) => {
    return {
        type: ActionTypes.IMAGE_STORED_REDUX,
        payload: date_,
    }
}

export const getCategoryList = (list) => {
    return {
        type: ActionTypes.GET_CATEGORY_LIST,
        payload: list,
    }
}

export const addCategoryList = (list) => {
    return {
        type: ActionTypes.ADD_CATEGORY_LIST,
        payload: list,
    }
}

export const updateCategoryList = (list) => {
    return {
        type: ActionTypes.UPDATE_CATEGORY_LIST,
        payload: list,
    }
}

export const getSubCategoryList = (list) => {
    return {
        type: ActionTypes.GET_SUB_CATEGORY_LIST,
        payload: list,
    }
}

export const addSubCategoryList = (list) => {
    return {
        type: ActionTypes.ADD_SUB_CATEGORY_LIST,
        payload: list,
    }
}

export const updateSubCategoryList = (list) => {
    return {
        type: ActionTypes.UPDATE_SUB_CATEGORY_LIST,
        payload: list,
    }
}

export const getSparesCampatibilityList = (list) => {
    return {
        type: ActionTypes.GET_SPARES_CAMPATIBILITY_LIST,
        payload: list,
    }
}

export const getAllSparesCampatibilityList = (list) => {
    return {
        type: ActionTypes.GET_ALL_SPARES_CAMPATIBILITY_LIST,
        payload: list,
    }
}

export const setGetMagicCode = (payload) => {
    return {
        type: ActionTypes.SET_MAGIC_CODE,
        payload: payload
    }
}

export const getAllBrandList = (list) => {
    return {
        type: ActionTypes.GET_ALL_BRANDS_LIST,
        payload: list,
    }
}

export const getAllCategoryList = (list) => {
    return {
        type: ActionTypes.GET_ALL_CATEGORY_LIST,
        payload: list,
    }
}

export const getAllSubCategoryList = (list) => {
    return {
        type: ActionTypes.GET_ALL_SUB_CATEGORY_LIST,
        payload: list,
    }
}

export const ActiveListitemStatus = (list) => {
    return {
        type: ActionTypes.ACTIVE_LIST_ITEM_STATUS,
        payload: list,
    }
}

export const clearRedux = () => {
    return {
        type: ActionTypes.ADMIN_LOGOUT,
    }
}

export const getProductList = (list) => {
    return {
        type: ActionTypes.GET_PRODUCT_LIST,
        payload: list,
    }
}

export const getBundleItemAllList = (list) => {
    return {
        type: ActionTypes.GET_BUNDLES_LIST_ALL,
        payload: list,
    }
}

export const addProductList = (list) => {
    return {
        type: ActionTypes.ADD_PRODUCT_LIST,
        payload: list,
    }
}

export const getAllProductList = (list) => {
    return {
        type: ActionTypes.GET_ALL_PRODUCT_LIST,
        payload: list,
    }
}

export const getAllProductCategoryList = (list) => {
    return {
        type: ActionTypes.GET_ALL_PRODUCT_CATEGORY_LIST,
        payload: list,
    }
}

export const updateProductList = (list) => {
    return {
        type: ActionTypes.UPDATE_PRODUCT_LIST,
        payload: list,
    }
}

export const getStateList = (list) => {
    return {
        type: ActionTypes.GET_STATE_LIST,
        payload: list,
    }
}

export const addStateList = (list) => {
    return {
        type: ActionTypes.ADD_STATE_LIST,
        payload: list,
    }
}

export const getAllStateList = (list) => {
    return {
        type: ActionTypes.GET_ALL_STATE_LIST,
        payload: list,
    }
}

export const updateStateList = (list) => {
    return {
        type: ActionTypes.UPDATE_STATE_LIST,
        payload: list,
    }
}

export const getCityList = (list) => {
    return {
        type: ActionTypes.GET_CITY_LIST,
        payload: list,
    }
}

export const getAllCityList = (list) => {
    return {
        type: ActionTypes.GET_ALL_CITY_LIST,
        payload: list,
    }
}

export const addCityList = (list) => {
    return {
        type: ActionTypes.ADD_CITY_LIST,
        payload: list,
    }
}

export const updateCityList = (list) => {
    return {
        type: ActionTypes.UPDATE_CITY_LIST,
        payload: list,
    }
}

export const getPinCodeList = (list) => {
    return {
        type: ActionTypes.GET_PIN_CODE_LIST,
        payload: list,
    }
}

export const getRegionMasterList = (list) => {
    return {
        type: ActionTypes.GET_REGION_MASTER_LIST,
        payload: list,
    }
}

export const addRegionMasterList = (list) => {
    return {
        type: ActionTypes.ADD_REGION_MASTER_LIST,
        payload: list,
    }
}

export const updateRegionMasterList = (list) => {
    return {
        type: ActionTypes.UPDATE_REGION_MASTER_LIST,
        payload: list,
    }
}

export const getUserList = (list) => {
    return {
        type: ActionTypes.GET_USER_LIST,
        payload: list,
    }
}

export const getAllUserList = (list) => {
    return {
        type: ActionTypes.GET_ALL_USER_LIST,
        payload: list,
    }
}

export const addUserList = (list) => {
    return {
        type: ActionTypes.ADD_USER_LIST,
        payload: list,
    }
}

export const updateUserList = (list) => {
    return {
        type: ActionTypes.UPDATE_USER_LIST,
        payload: list,
    }
}

export const getAllRegionList = (list) => {
    return {
        type: ActionTypes.GET_ALL_REGION_LIST,
        payload: list,
    }
}

export const getWareHouseList = (list) => {
    return {
        type: ActionTypes.GET_WAREHOUSE_LIST,
        payload: list,
    }
}

export const addWareHouseList = (list) => {
    return {
        type: ActionTypes.ADD_WAREHOUSE_LIST,
        payload: list,
    }
}

export const updateWareHouseList = (list) => {
    return {
        type: ActionTypes.UPDATE_WAREHOUSE_LIST,
        payload: list,
    }
}

export const getAllWareHouseList = (list) => {
    return {
        type: ActionTypes.GET_ALL_WAREHOUSE_LIST,
        payload: list,
    }
}

export const SearchPincodeList = (list) => {
    return {
        type: ActionTypes.PINCODES_SEARCH_LIST,
        payload: list,
    }
}

export const SearchCommonPincodeList = (list) => {
    return {
        type: ActionTypes.COMMON_PINCODES_SEARCH_LIST,
        payload: list,
    }
}

export const StartCommonLoader = (loader) => {
    return {
        type: ActionTypes.COMMON_LOADER,
        payload: loader,
    }
}

export const StartMainCommonLoader = (loaderData_) => {
    return {
        type: ActionTypes.COMMON_MAIN_LOADER,
        payload: loaderData_,
    }
}

export const ErrorMessageFromRedux = (error) => {
    return {
        type: ActionTypes.ERROR_MESSAGE_FROM_REDUX,
        payload: error,
    }
}

export const ErrorFramesMessageFromRedux = (error) => {
    return {
        type: ActionTypes.ERROR_FRAMES_MESSAGE_FROM_REDUX,
        payload: error,
    }
}

export const StoredDetailReport = (list) => {
    return {
        type: ActionTypes.STORED_DETAIL_REPORT_LIST,
        payload: list,
    }
}

export const StoredDetailLogReport = (list) => {
    return {
        type: ActionTypes.STORED_DETAIL_LOG_REPORT_LIST,
        payload: list,
    }
}

export const setStoreAccessoriesReport = (list) => {
    return {
        type: ActionTypes.SET_STORE_ACCESSORIES_REPORT,
        payload: list,
    }
}

export const StoredLevelReport = (list) => {
    return {
        type: ActionTypes.STORED_LEVEL_REPORT_LIST,
        payload: list,
    }
}

export const StoredLevelAllReport = (list) => {
    return {
        type: ActionTypes.STORED_LEVEL_ALL_REPORT_LIST,
        payload: list,
    }
}

export const SoApprovalStoredLevelReport = (list) => {
    return {
        type: ActionTypes.SO_APPROVAL_STORED_LEVEL_REPORT_LIST,
        payload: list,
    }
}

export const GetFranchiseTypeList = (list) => {
    return {
        type: ActionTypes.FRANCHISE_TYPE_LIST,
        payload: list,
    }
}

export const addFranchiseTypeList = (list) => {
    return {
        type: ActionTypes.ADD_FRANCHISE_TYPE_LIST,
        payload: list,
    }
}

export const updateFranchiseTypeList = (list) => {
    return {
        type: ActionTypes.UPDATE_FRANCHISE_TYPE_LIST,
        payload: list,
    }
}

export const GetFranchiseUserTypeList = (list) => {
    return {
        type: ActionTypes.GET_FRANCHISE_USER_TYPE_LIST,
        payload: list,
    }
}

export const GetAllDealerFranchiseUserTypeList = (list) => {
    return {
        type: ActionTypes.GET_ALL_DEALER_FRANCHISE_USER_TYPE_LIST,
        payload: list,
    }
}

export const addFranchiseUserTypeList = (list) => {
    return {
        type: ActionTypes.ADD_FRANCHISE_USER_TYPE_LIST,
        payload: list,
    }
}

export const updateFranchiseUserTypeList = (list) => {
    return {
        type: ActionTypes.UPDATE_FRANCHISE_USER_TYPE_LIST,
        payload: list,
    }
}

export const GetAllFranchiseTypeList = (list) => {
    return {
        type: ActionTypes.GET_ALL_FRANCHISE_TYPE_LIST_LIST,
        payload: list,
    }
}

export const GetProductGroupList = (list) => {
    return {
        type: ActionTypes.GET_PRODUCT_GROUP_LIST,
        payload: list,
    }
}

export const GetAllProductGroupList = (list) => {
    return {
        type: ActionTypes.GET_ALL_PRODUCT_GROUP_LIST,
        payload: list,
    }
}

export const addProductGroupList = (list) => {
    return {
        type: ActionTypes.ADD_PRODUCT_GROUP_LIST,
        payload: list,
    }
}

export const updateProductGroupList = (list) => {
    return {
        type: ActionTypes.UPDATE_PRODUCT_GROUP_LIST,
        payload: list,
    }
}

export const getAfterSalesOrdersList = (list) => {
    return {
        type: ActionTypes.GET_AFTER_SELES_ORDERS_LIST,
        payload: list,
    }
}

export const FranchiseOrdersReportList = (list) => {
    return {
        type: ActionTypes.FRANCHISE_ORDER_REPORT_LIST,
        payload: list,
    }
}

export const GetOrderReportDetails = (details) => {
    return {
        type: ActionTypes.ORDER_DETAILS_REPORT,
        payload: details,
    }
}

export const GetafterSalesOrdersDetails = (details) => {
    return {
        type: ActionTypes.GET_AFTER_SALES_ORDER_DETAILS_REPORT,
        payload: details,
    }
}

export const GetOrderDetailByOrderCode = (details) => {
    return {
        type: ActionTypes.ORDER_DETAILS_BY_ORDER_CODE,
        payload: details,
    }
}

export const setStoreAccessoriesList = (details) => {
    return {
        type: ActionTypes.SET_STORE_ACCESSORIES_LIST,
        payload: details,
    }
}

export const GetPOApprovalsOrderList = (list) => {
    return {
        type: ActionTypes.ORDER_PO_APPROVALS_ORDERS,
        payload: list,
    }
}

export const GetWareHouseApprovalsOrderList = (list) => {
    return {
        type: ActionTypes.ORDER_WAREHOUSE_APPROVALS_ORDERS,
        payload: list,
    }
}

export const PendingForAccountsOrderList = (list) => {
    return {
        type: ActionTypes.PENDING_FOR_ACCOUNT_ORDERS_LIST,
        payload: list,
    }
}

export const PendingForStoresOrderList = (list) => {
    return {
        type: ActionTypes.PENDING_FOR_STORES_ORDERS_LIST,
        payload: list,
    }
}

export const GetDispatchList = (list) => {
    return {
        type: ActionTypes.DISPATCH_LIST,
        payload: list,
    }
}

export const addDispatchList = (list) => {
    return {
        type: ActionTypes.ADD_DISPATCH_LIST,
        payload: list,
    }
}

export const updateDispatchList = (list) => {
    return {
        type: ActionTypes.UPDATE_DISPATCH_LIST,
        payload: list,
    }
}

export const GetAllDispatchList = (list) => {
    return {
        type: ActionTypes.GET_ALL_DISPATCH_LIST,
        payload: list,
    }
}

export const GetStockInwardList = (list) => {
    return {
        type: ActionTypes.GET_STOCK_INWARD_LIST,
        payload: list,
    }
}

export const GetReturnReplaceOrderList = (list) => {
    return {
        type: ActionTypes.GET_RETURN_REPLACE_ORDER_LIST,
        payload: list,
    }
}

export const GetReturnReplaceSingleOrderList = (list) => {
    return {
        type: ActionTypes.GET_RETURN_REPLACE_SINGLE_ORDER,
        payload: list,
    }
}

export const GetReturnReplaceInvoiceOrderDetails = (details) => {
    return {
        type: ActionTypes.GET_RETURN_REPLACE_INVOICE_ORDER_DETAILS,
        payload: details,
    }
}

export const GetReturnReplaceFramesOrderDetails = (details) => {
    return {
        type: ActionTypes.GET_RETURN_REPLACE_FRAMES_ORDER_DETAILS,
        payload: details,
    }
}

export const setStoreModalInvoiceDetails = (list) => {
    return {
        type: ActionTypes.SET_STORE_MODAL_INVOICE_DETAILS,
        payload: list,
    }
}

export const getBoxList = (list) => {
    return {
        type: ActionTypes.GET_BOX_LIST,
        payload: list,
    }
}

export const addBoxList = (list) => {
    return {
        type: ActionTypes.ADD_BOX_LIST,
        payload: list,
    }
}

export const getAllBoxList = (list) => {
    return {
        type: ActionTypes.GET_ALL_BOX_LIST,
        payload: list,
    }
}

export const updateBoxList = (list) => {
    return {
        type: ActionTypes.UPDATE_BOX_LIST,
        payload: list,
    }
}

export const getAllReasonTypeList = (list) => {
    return {
        type: ActionTypes.GET_ALL_REASON_TYPE_LIST,
        payload: list,
    }
}

export const getAllReasonList = (list) => {
    return {
        type: ActionTypes.GET_ALL_REASON_LIST,
        payload: list,
    }
}

export const addDebitCreditNoteList = (list) => {
    return {
        type: ActionTypes.ADD_DEBIT_CREDIT_NOTE,
        payload: list,
    }
}

export const getAllDebitCreditNoteList = (list) => {
    return {
        type: ActionTypes.GET_ALL_CREDIT_DEBIT_LIST,
        payload: list,
    }
}

export const getDebitCreditNoteReportList = (list) => {
    return {
        type: ActionTypes.GET_DEBIT_CREDIT_NOTE_REPORT_LIST,
        payload: list,
    }
}

export const getPaymentApprovalList = (list) => {
    return {
        type: ActionTypes.GET_PAYMENT_APPROVAL_LIST_LIST,
        payload: list,
    }
}

export const getDncnLedgerList = (list) => {
    return {
        type: ActionTypes.GET_DNCN_LEDGER_LIST,
        payload: list,
    }
}

export const addPaymentList = (list) => {
    return {
        type: ActionTypes.ADD_PAYMENT_LIST,
        payload: list,
    }
}

export const getGSTReportList = (list) => {
    return {
        type: ActionTypes.GET_GST_REPORT_LIST,
        payload: list,
    }
}

export const getTableSorting = (list) => {
    return {
        type: ActionTypes.TABLE_SORTING_BY,
        payload: list,
    }
}

export const getPurchaseListFct = (list) => {
    return {
        type: ActionTypes.PURCHASE_LIST,
        payload: list,
    }
}

export const updatePurchaseListFct = (list) => {
    return {
        type: ActionTypes.UPDATE_PURCHASE_LIST,
        payload: list,
    }
}

export const GetFrameList = (list) => {
    return {
        type: ActionTypes.GET_ALL_FRAME_LIST,
        payload: list,
    }
}

export const getOrderLogList = (list) => {
    return {
        type: ActionTypes.GET_ORDER_LOG_LIST,
        payload: list,
    }
}

export const addFranchiseBrandList = (list) => {
    return {
        type: ActionTypes.ADD_FRANCHISE_BRAND_LIST,
        payload: list,
    }
}

export const removeFranchiseBrandList = (list) => {
    return {
        type: ActionTypes.REMOVE_FRANCHISE_BRAND,
        payload:list
    }
}

export const getInwardLogList = (list) => {
    return {
        type: ActionTypes.GET_INWARD_LOG_LIST,
        payload: list,
    }
}

export const getWareHousePinCodeListList = (list) => {
    return {
        type: ActionTypes.GET_WAREHOUSE_WITH_PINCODE_LIST,
        payload: list,
    }
}

export const getFranchiseOrderLatestThreeList = (list) => {
    return {
        type: ActionTypes.FRANCHISE_ORDER_LATEST_THREE_LIST,
        payload: list,
    }
}

export const getPointValueList = (list) => {
    return {
        type: ActionTypes.POINT_VALUE_LIST,
        payload: list,
    }
}

export const addPointValueList = (list) => {
    return {
        type: ActionTypes.ADD_POINT_VALUE_LIST,
        payload: list,
    }
}

export const updatePointValueList = (list) => {
    return {
        type: ActionTypes.UPDATE_POINT_VALUE_LIST,
        payload: list,
    }
}

export const updateOrderManagementFilter = (filter) => {
    return {
        type: ActionTypes.UPDATE_ORDER_MANAGEMENT_FILTER,
        payload: {
            modulename: Object?.keys(filter)[0],
            Date: filter,
        },
    }
}

export const GetRefurbishedSettings = (setting) => {
    return {
        type: ActionTypes.GET_REFURBISHED_SETTINGS,
        payload: setting,
    }
}

export const UpdateRefurbishedSettings = (setting) => {
    return {
        type: ActionTypes.UPDATE_REFURBISHED_SETTINGS,
        payload: setting,
    }
}

export const getERPAccountList = (list) => {
    return {
        type: ActionTypes.GET_ERP_ACCOUNT_LIST_LIST,
        payload: list,
    }
}

export const getErpAccountLeadgerList = (list) => {
    return {
        type: ActionTypes.GET_ERP_ACCOUNT_LEADGER_LIST,
        payload: list,
    }
}

export const getErpGeneralLeadgerList = (list) => {
    return {
        type: ActionTypes.GET_ERP_GENERAL_LEADGER_LIST,
        payload: list,
    }
}

export const getResellcycleReportsList = (list) => {
    return {
        type: ActionTypes.GET_RESELLCYCLE_REPORT_LIST,
        payload: list,
    }
}

export const getResellcycleCustomerReportsList = (list) => {
    return {
        type: ActionTypes.GET_RESELLCYCLE_CUSTOMER_REPORT_LIST,
        payload: list,
    }
}

export const NavigationPointList = (tab) => {
    return {
        type: ActionTypes.GET_NAVIGATIONS_POINT_LIST,
        payload: tab,
    }
};

export const getPointSettings = (list) => {
    return {
        type: ActionTypes.GET_POINTS_SETTINGS_LIST,
        payload: list,
    }
}

export const addPointSettings = (list) => {
    return {
        type: ActionTypes.ADD_POINTS_SETTINGS_LIST,
        payload: list,
    }
}

export const updatePointSettings = (list) => {
    return {
        type: ActionTypes.UPDATE_POINTS_SETTINGS_LIST,
        payload: list,
    }
}

export const deletePointSettings = (list) => {
    return {
        type: ActionTypes.DELETE_POINTS_SETTINGS_LIST,
        payload: list,
    }
}

export const getAgeValueList = (list) => {
    return {
        type: ActionTypes.GET_AGE_VALUE_LIST,
        payload: list,
    }
}

export const addAgeValue = (list) => {
    return {
        type: ActionTypes.ADD_AGE_VALUE_LIST,
        payload: list,
    }
}

export const updateAgeValue = (list) => {
    return {
        type: ActionTypes.UPDATE_AGE_VALUE_LIST,
        payload: list,
    }
}

export const deleteAgeValue = (list) => {
    return {
        type: ActionTypes.DELETE_AGE_VALUE,
        payload: list,
    }
}

export const getResallBrandList = (list) => {
    return {
        type: ActionTypes.GET_RESELL_BRAND_LIST,
        payload: list,
    }
}

export const addResallBrand = (list) => {
    return {
        type: ActionTypes.ADD_RESELL_BRAND,
        payload: list,
    }
}

export const updateResallBrand = (list) => {
    return {
        type: ActionTypes.UPDATE_RESELL_BRAND,
        payload: list,
    }
}

export const getErpApiFailuresList = (list) => {
    return {
        type: ActionTypes.GET_ERP_API_FAILURE_LIST,
        payload: list,
    }
}

export const getErpInvoiceApiFailuresList = (list) => {
    return {
        type: ActionTypes.GET_ERP_INVOICE_API_FAILURE_LIST,
        payload: list,
    }
}

export const getErpDeliveryNoteFrameNumberFailuresList = (list) => {
    return {
        type: ActionTypes.GET_ERP_DELIVERY_NOTE_FRAME_NUMBER_FAILURE_LIST,
        payload: list,
    }
}

export const getErpPurchaseTrueList = (list) => {
    return {
        type: ActionTypes.GET_ERP_PURCHASE_TRUE_LIST,
        payload: list,
    }
}

export const getErpPurchaseFalseList = (list) => {
    return {
        type: ActionTypes.GET_ERP_PURCHASE_FALSE_LIST,
        payload: list,
    }
}

export const getSearchModulesObjects = (data) => {
    return {
        type: ActionTypes.GET_SEARCH_MODULES_OBJECT,
        payload: data,
    }
}

export const getFBAOrderList = (list) => {
    return {
        type: ActionTypes.GET_FBA_ORDER_LIST,
        payload: list,
    }
}

export const getSalesPersonPerformanceList = (data) => {
    return {
        type: ActionTypes.GET_SALES_PERSON_PERFORMANCE_LIST,
        payload: data,
    }
}

export const getAllSalesPersonPerformanceList = (data) => {
    return {
        type: ActionTypes.GET_ALL_SALES_PERSON_PERFORMANCE_LIST,
        payload: data,
    }
}

export const getIncentiveSlabList = (data) => {
    return {
        type: ActionTypes.GET_INCENTIVE_SLAB_LIST,
        payload: data,
    }
}

export const addIncentiveSlabList = (data) => {
    return {
        type: ActionTypes.ADD_INCENTIVE_SLAB_LIST, 
        payload: data,
    }
}

export const updateIncentiveSlabList = (data) => {
    return {
        type: ActionTypes.UPDATE_INCENTIVE_SLAB_LIST,
        payload: data,
    }
}

export const getOrderCancelStatus = (data) => {
    return {
        type: ActionTypes.GET_ORDER_CANCEL_STATUS,
        payload: data,
    }
}

export const getDarkLightModeFunction = (data) => {
    return {
        type: ActionTypes.GET_DARK_LIGHT_MODE,
        payload: data,
    }
}

export const getFranchiseSchemeList = (data) => {
    return {
        type: ActionTypes.GET_FRANCHISE_SCHEME_LIST,
        payload: data,
    }
}

export const addFranchiseSchemeList = (data) => {
    return {
        type: ActionTypes.ADD_FRANCHISE_SCHEME_LIST,
        payload: data,
    }
}

export const updateFranchiseSchemeList = (data) => {
    return {
        type: ActionTypes.UPDATE_FRANCHISE_SCHEME_LIST,
        payload: data,
    }
}

export const getAllFranchiseSchemeList = (data) => {
    return {
        type: ActionTypes.GET_ALL_FRANCHISE_SCHEME_LIST,
        payload: data,
    }
}

export const GetUnicommerceInvoiceFalureList = (data) => {
    return {
        type: ActionTypes.GET_UNICOMMERCE_INVOICE_FAILURE_LIST,
        payload: data,
    }
}

export const GetUnicommerceShipmentFalureList = (data) => {
    return {
        type: ActionTypes.GET_UNICOMMERCE_SHIPMENT_FAILURE_LIST,
        payload: data,
    }
}

export const GetUnicommerceTrackStatusFalureList = (data) => {
    return {
        type: ActionTypes.GET_UNICOMMERCE_TRACK_STATUS_FAILURE_LIST,
        payload: data,
    }
}

export const GetTicketsList = (data) => {
    return {
        type: ActionTypes.GET_TICKETS_LIST,
        payload: data,
    }
}