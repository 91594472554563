/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Image, Collapse } from 'react-bootstrap';
import DataTableFilter from "../Common/DataTableFilter";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Link, useNavigate } from "react-router-dom";
import { WebSocketContext } from "../../App";
import { ADD_INCENTIVE_SLAB_URL, EDIT_INCENTIVE_SLAB_URL } from "../Shared/constant";
import { ClearFormSetFutios } from "../../redux/actions/adminActions";
import wsSend_request from "../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import AmountNumberFormat from "../Common/AmountNumberFormat";
import MoreIcon from '../../assets/img/more_options.png';
import DeleteModal from "../Common/DeleteModal";

const IncentiveSlab = (props) => {
    const { RouteName } = props;
    const { IncentiveSlabList, device_id, selfInfo } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ activeTableDetails, setActiveTableDetails ] = useState("");
    const [ deleteCommonStateModal, setdeleteCommonStateModal ] = useState({
        title: "",
        description: "",
        open: false,
        delete_id: "",
        delete_url: ""
    });
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // show more user details
    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // hide more user details
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };

    // api calling
    const APICall = () => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.DealerApp[0]?.Edit || PermissionsAccess?.DealerApp[0]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "salesperson_incentive_slab",
                "request" : { 
                    "status": userType === "active" ? true : userType === "inactive" ? false : "",
                    "limit" : 5000,
                    "search" : userSearch?.trim(),
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket, userSearch, userType, currentPage, userLimit ]);

    // delete incentive
    const DeleteIncentive = (data) => {
        setdeleteCommonStateModal({
            ...deleteCommonStateModal,
            title: "<i class='bi bi-trash3' style='color: red'></i> Delete Incentive",
            description: `Are you sure want to delete this incentive ?`,
            open: true,
            delete_id: data?.incentive_code,
            delete_url: "incentive-delete"
        });
    };

    // create points
    const cmnFnct = () => {
        Navigate(ADD_INCENTIVE_SLAB_URL);
    };

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
    }, []);

    // update incentive groups into list
    const UpdateIncentiveSlabList = Object.values(
        IncentiveSlabList?.list?.reduce((acc, { title, incentive_code, slab, range_from, range_to, amount }) => {
            if (!acc[incentive_code]) {
                acc[incentive_code] = {
                    title,
                    incentive_code,
                    incentives: [] 
                };
            };
            
            // Push slab details to the incentives array
            acc[incentive_code].incentives.push({ slab, range_from, range_to, amount });
            
            return acc;
        }, {})
    );

    return(<section className="Main_section incentive_slab_Main_section">
        <DataTableFilter 
            filterType={""}
            searchType={"Title, Incentive Code"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.DealerApp[0]?.Edit) ? true : false}
            ButtonSet={{
                buttontitle: "Create Incentive Slab",
                function: cmnFnct
            }}
            searchdisable={true}
            ActiveInactiveFilter={false}
        />
        <div className="tableView">
            <Scrollbars 
                style={{ height: UpdateIncentiveSlabList?.pagination?.total_records > 10 ? "calc(100vh - 186px)" : "calc(100vh - 150px)"}} 
                renderView={props => <div {...props} className="view"/>}
                className="ScrollbarsSidebar"
            >
                <Table bordered>
                    <thead>
                        <tr>
                            <th></th>
                            <th>SNO</th>
                            <th>Title</th>
                            <th>Incentive Code</th>
                            {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.DealerApp[0]?.Edit) && (<th>Action</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {UpdateIncentiveSlabList?.map((elm, index) => {
                            return(<React.Fragment key={index}>
                                <tr key={index}>
                                    <td style={{ width: '10px' }}>
                                        <button 
                                            className={activeTableDetails === elm?.incentive_code ? "btn actionbtn active" : "btn actionbtn"}
                                            onClick={() => activeTableDetails === elm?.incentive_code ? ElseMoreDetails() : ViewMoreDetails(elm?.incentive_code)}
                                            aria-controls="example-collapse-text"
                                            aria-expanded={activeTableDetails === elm?.incentive_code ? true : false}
                                        >
                                            <Image src={MoreIcon} alt="more icon" />
                                        </button>
                                    </td>
                                    <td>{index + 1}</td>
                                    <td>{elm?.title === null ? "-" : elm?.title}</td>
                                    <td>{elm?.incentive_code === null ? "-" : elm?.incentive_code}</td>
                                    {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.DealerApp[0]?.Edit) && (<td>
                                        <Link className="btn edittable" to={`${EDIT_INCENTIVE_SLAB_URL}/${elm?.incentive_code}`}>
                                            <i className="bi bi-pencil-square"></i>
                                        </Link>
                                        <button className="btn edittable" style={{ marginLeft: "5px" }} onClick={() => DeleteIncentive(elm)}>
                                            <i className="bi bi-trash3"></i>
                                        </button>
                                    </td>)}
                                </tr>
                                <Collapse in={activeTableDetails === elm?.incentive_code ? true : false}>
                                    <tr className="tablecollapsewraps" id={"tablecollpase"+elm?.incentive_code}>
                                        <td colSpan="8" >
                                            <Table bordered  className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Slab</th>
                                                        <th>Range From</th>
                                                        <th>Range to</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {elm?.incentives?.map((elmz, index) => {
                                                        return(<tr key={index}>
                                                            <td>{elmz?.slab}</td>
                                                            <td>{elmz?.range_from}</td>
                                                            <td>{elmz?.range_to}</td>
                                                            <td>{AmountNumberFormat(elmz?.amount)}</td>
                                                        </tr>)
                                                    })}
                                                </tbody>
                                            </Table>
                                        </td>
                                    </tr>
                                </Collapse>
                            </React.Fragment>)
                        })}
                        {UpdateIncentiveSlabList?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="8">incentive slab list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
        </div>

        {/* delete scheme  */}
        {deleteCommonStateModal?.open && (<DeleteModal 
            setdeleteCommonStateModal={setdeleteCommonStateModal} 
            deleteCommonStateModal={deleteCommonStateModal} 
        />)}
    </section>)
}

export default IncentiveSlab;