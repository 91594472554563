/* eslint-disable */
import React, { useEffect, useContext } from "react";
import { Table, Form } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonDateTime from "../../Common/CommonDateTime";
import AmountNumberFormat from "../../Common/AmountNumberFormat";
import { Link, useParams, useNavigate  } from "react-router-dom";
import { AFTER_SALES_ORDERS_URL } from "../../Shared/constant";
import { GetafterSalesOrdersDetails } from "../../../redux/actions/adminActions";

const AfterSalesOrdersDetails = (props) => {
    const { RouteName } = props;
    const { roomId } = useParams();
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const { websocket } = useContext(WebSocketContext);
    const { device_id, AfterSalesOrderReportDetails } = useSelector((state) => state.adminReducers);
    
    useEffect(() => {
        if(roomId) {
            let param = { 
                "transmit": "broadcast", 
                "url": "afs_order_detail",
                "request" : { 
                    "order_id" : roomId,
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        }
    }, [ roomId ]);

    // get total balance and Credit Limit	
    const GetCurrentOrderRate = (current) => {
        const getCurrentFranchise = current?.order?.billing;
        const getCurrentFranchiseType = current?.franchise_type_detail;

        let creditlimit = 0;
        let wallet = 0
        if(getCurrentFranchiseType?.franchise_type_credit !== null && getCurrentFranchiseType?.franchise_type_credit !== "") {
            wallet = parseInt(getCurrentFranchiseType?.franchise_type_credit);
            creditlimit = parseInt(getCurrentFranchiseType?.franchise_type_credit);
        };
        if(getCurrentFranchise?.credit_addon !== null && getCurrentFranchise?.credit_addon !== "") {
            wallet = parseInt(getCurrentFranchise?.credit_addon) + wallet;
            creditlimit = parseInt(getCurrentFranchise?.credit_addon) + creditlimit;
        }; 
        if(getCurrentFranchise?.advance_amount !== null && getCurrentFranchise?.advance_amount !== "") {
            wallet = wallet + parseInt(getCurrentFranchise?.advance_amount);
        };

        return {
            Wallet_balance: (getCurrentFranchise?.advance_amount === null || getCurrentFranchise?.advance_amount === "") ? 0 :parseInt(getCurrentFranchise?.advance_amount),
            credit_limit: creditlimit,
            available_balance: wallet,
        };
    };

    return(<section className="Main_section">
        <div className="MainHeader">
            <Link to={AFTER_SALES_ORDERS_URL} onClick={() => Dispatch(GetafterSalesOrdersDetails({}))} className="btn btn-link backbtn"><i className="bi bi-arrow-left-short"></i> Back</Link>
        </div>
        {AfterSalesOrderReportDetails !== undefined && Object.keys(AfterSalesOrderReportDetails)?.length > 0 ? (<>
            <div className="filtr_Form"  style={{ paddingBottom: "10px", borderBottom: "1px solid #eee" }}>
                <div className="formloginset formloginsetStaticData">
                    <div className="row">
                        {AfterSalesOrderReportDetails?.order?.order_type !== "CO" && (<div className="col-md-3" >
                            <Form.Group className="mb-3 ">
                                <Form.Label>Franchise Name</Form.Label>
                                <div className="defaultvalueField">
                                {AfterSalesOrderReportDetails?.order?.customer_name}
                                </div>
                            </Form.Group>
                        </div>)}
                        <div className="col-md-3">
                            <Form.Group className="mb-3 ">
                                <Form.Label>Request No</Form.Label>
                                <div className="defaultvalueField">
                                    {AfterSalesOrderReportDetails?.order?.order_code}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-3" >
                            <Form.Group className="mb-3 ">
                                <Form.Label>Request Date</Form.Label>
                                <div className="defaultvalueField">
                                    {CommonDateTime(AfterSalesOrderReportDetails?.order?.created_at, "LL", "show")}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-3" >
                            <Form.Group className="mb-3 ">
                                <Form.Label>Total Qty</Form.Label>
                                <div className="defaultvalueField">
                                {AfterSalesOrderReportDetails?.order?.qty}
                                </div>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3" >
                            <Form.Group>
                                <Form.Label>State:</Form.Label>
                                <div className="defaultvalueField">
                                {AfterSalesOrderReportDetails?.order?.state}
                                </div>
                            </Form.Group>
                        </div>
                        {AfterSalesOrderReportDetails?.order?.order_type === "FO" && (<>
                            <div className="col-md-3" >
                                <Form.Group>
                                    <Form.Label>Wallet Balance:</Form.Label>
                                    <div className="defaultvalueField">
                                    {AmountNumberFormat(GetCurrentOrderRate(AfterSalesOrderReportDetails)?.Wallet_balance)}
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-3" >
                                <Form.Group>
                                    <Form.Label>Credit Limit:</Form.Label>
                                    <div className="defaultvalueField">
                                    {AmountNumberFormat(GetCurrentOrderRate(AfterSalesOrderReportDetails)?.credit_limit)}
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-3" >
                                <Form.Group>
                                    <Form.Label>Available Balance:</Form.Label>
                                    <div className="defaultvalueField">
                                    {AmountNumberFormat(GetCurrentOrderRate(AfterSalesOrderReportDetails)?.available_balance)}
                                    </div>
                                </Form.Group>
                            </div>
                        </>)}
                    </div>
                </div>
            </div>
            <div className="tableView gstreporttableadded">
                <Scrollbars 
                    style={{ height: (AfterSalesOrderReportDetails?.order?.order_type === "AO" || AfterSalesOrderReportDetails?.order?.order_type === "FO") ? "calc(100vh - 342px)" : "calc(100vh - 249px)"}} 
                    renderView={props => <div {...props} className="view"/>}
                    className="ScrollbarsSidebar"
                >
                    <Table id="stock_level_so_approval" bordered  >
                        <thead>
                            <tr>
                                <th>SNo</th>
                                <th>Code</th>
                                <th>Product Name</th>
                                <th>Amount</th>
                                <th>Unit Amount</th>
                                <th>Tax Amount</th>
                                <th>Total Qty</th>
                            </tr>
                        </thead>
                        <tbody>
                            {AfterSalesOrderReportDetails?.order_items_list?.map((elm, index) => {
                                return(<tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{elm?.product_code}</td>
                                    <td>{elm?.product_name}</td>
                                    <td>{elm?.sub_total}</td>
                                    <td>{parseInt(elm?.price).toFixed(2)}</td>
                                    <td>{elm?.sub_gst}</td>
                                    <td>{elm?.qty}</td>
                                </tr>)
                            })}
                        </tbody>
                    </Table>
                </Scrollbars>
            </div>
        </>) : (<>
            Please Wait...
        </>)}
    </section>)
}

export default AfterSalesOrdersDetails;