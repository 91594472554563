import React from "react";
import { Button } from "react-bootstrap";

const WebSocketDisconnectModal = (props) => {
    const { WebsocketCheck } = props;

    return(<div className='notinternetFoundPage'>
        <div className='connectwrapp'>
           <i className="bi bi-wifi-off"></i>
           <h2>Whoops!!</h2>
           <p>{WebsocketCheck === "websocket close" ? "Disconnected from the server, please wait a few minutes." : "No internet connection was found. check your connection or try again."}</p>
           <Button onClick={() => window.location.reload()}>Try again</Button>
        </div>
    </div>)
}

export default WebSocketDisconnectModal;