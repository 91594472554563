import { STOCK_SYNC_URL } from "../components/Shared/constant";

const StockSyncAPI = async (accessToken) => {
    const responce = window.axios.get(STOCK_SYNC_URL, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + accessToken,
    }}).then(function (result) {
        return {
          ...result.data,
          status:200
        };
    }).catch(function (result) {
        return {
          ...result?.response?.data,
          status:result?.response?.status
        }
    });
    return responce;
};
  
export default StockSyncAPI;