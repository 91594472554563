import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { useSelector } from "react-redux";

const AuthLayout = ({children}) => {
    const [divWidth, setDivWidth] = useState(null);
    const { darkmode } = useSelector((state) => state.adminReducers);
    const darkmodeclassName = darkmode === undefined ? "light" : darkmode;

    return(<section className={`adminmain ${darkmodeclassName}`}>
        <Header sidebarWidth={divWidth} />
        <Sidebar setDivWidth={setDivWidth} />
        <div className={"panelwrapper active"}>
            <div className="panelContentwrapper">
                {children}
            </div>
        </div>
    </section>)
}

export default AuthLayout;