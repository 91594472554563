/* eslint-disable */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Modal, Button, Table, Form  } from 'react-bootstrap';
import Logo from "../../../assets/img/logo.png";
import { useSelector, useDispatch } from "react-redux";
import { ClearFormSetFutios, GetOrderReportDetails } from "../../../redux/actions/adminActions";
import CommonDateTime from "./../../Common/CommonDateTime";
import AmountNumberFormat from "./../../Common/AmountNumberFormat";
import Scrollbars from "react-custom-scrollbars-2";
import { Link, useNavigate, useParams } from "react-router-dom";
import wsSend_request from "../../../Api/ws/ws_request";
import { WebSocketContext } from "../../../App";
import { FBA_ORDER_LIST } from "../../Shared/constant";
import SelectReact from "../../Common/SelectReact";
import FrameSelectionItem from "../../Common/FrameSelectionItem";
import PurchaseOrderReport from "../../Common/PurchaseOrderReport";
import CancelOrder from "../../Common/CancelOrder";
import Select from 'react-select';
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import CommonewModel from "../../Common/CommonewModel";
import { toast } from "react-toastify";
import OrderStApproval from "../../../Api/OrderStApproval";
import CommonLoader from "../../Common/CommonLoader";
import GetWhatsappLmeAPI from "../../../Api/GetWhatsappLmeAPI";

const OrderDetailsViewer = (props) => {
    const { OrderViewerModal, setOrderViewerModal } = props;
    const { OrderReportDetails, device_id, OptionList, ClearFormSet, AllboxList, accessToken } = useSelector((state) => state.adminReducers);
    const Dispatch = useDispatch();
    const [items, setItems] = useState([]);
    const Navigate = useNavigate();
    const [disable, setDisable] = useState(true)
    const { websocket } = useContext(WebSocketContext);
    const getOptionsItem = () =>{
        const frameList = [];
        const AccessoriesList = [];
        let QTY = 0;
        OrderReportDetails?.order_items_list?.map((item, index)=>{
            if(item?.is_bike){
                frameList?.push(item);
                QTY = QTY + item?.qty;
            }else{
                AccessoriesList?.push(item);
                QTY = QTY + item?.qty;
            }
        })
        return {frameList, AccessoriesList, quantity:QTY}
    }
    const {frameList, AccessoriesList, quantity} = useMemo(getOptionsItem, [OrderReportDetails, OrderReportDetails?.order_items_list?.length, OptionList]);
    const roomId = useParams();

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };
    const exceptThisCostSymbols = ["e", "E", "+", "-", "."];
    const exceptThisCostSymbolsNew = ["e", "E", "+", "-"];

    const [ selectBox, setSelectBox ] = useState("");
    const GetOnlyAccessoriesProduct = OrderReportDetails?.order_items_list?.filter((elm) => elm?.is_bike === false);
    const GetOnlyBikesProduct = OrderReportDetails?.order_items_list?.filter((elm) => elm?.is_bike === true);
    const totalQuantity = calculateTotalQuantity(GetOnlyAccessoriesProduct);
    const totalBikesQuantity = calculateTotalQuantity(GetOnlyBikesProduct);
    const [ partialArray, setpartialArray ] = useState([]);
    const [ optionsBox, SetOptionsBox ] = useState([]);
    const [ optionsProduct, SetOptionsProduct ] = useState([]);
    const [ showModalNew, setShowModalNew ] = useState({
        open: false,
        title: "",
        subtitle: "",
        modalType: "",
        button: ""
    });
    const [ MainLoader, setMainLoader ] = useState(false);

    useEffect(() => {
        if(roomId?.roomId) {
            let param = { 
                "transmit": "broadcast", 
                "url": "fba_order_detail",
                "request" : { 
                    "order_id" : roomId?.roomId,
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        }
    }, [ roomId?.roomId, websocket ]);

    

    const callBack = (item, index, url) =>{
        const getItems = items?.filter((item, index1)=>item?.id != index);
        getItems.push({id:index, store_ids: item});
        let len = 0;
        getItems?.map((item)=>{
            len = len + item?.store_ids?.length
        })
        
        let frameListArray = [];
        getItems?.filter((elm) => {
            frameList?.filter((elmz) => {
                if(elmz?.id === elm?.id) {
                    frameListArray?.push(elm)
                };
            })
        });

        // Function to calculate total quantity
        function calculateTotalFrameQuantity(items) {
            if(items !== undefined) {
                let totalQuantity = 0;
                for (let i = 0; i < items.length; i++) {
                    totalQuantity += items[i].store_ids?.length;
                }
                return totalQuantity;
            }
        };

        if(AccessoriesList?.length === 0 && frameList?.length > 0) {
            if(url !== "Accessories") {
                if(len === quantity){
                    setDisable(false);
                }else{
                    setDisable(true);
                };
            }
        } else if(AccessoriesList?.length > 0 && frameList?.length > 0) {
            if(calculateTotalFrameQuantity(frameListArray) === totalBikesQuantity) {
                setDisable(false)
            } else {
                setDisable(true)
            };
        } else if(AccessoriesList?.length > 0 && frameList?.length === 0) {
            setDisable(false)
        };
        setItems(getItems)
    };

    const EmptyAccessories = AccessoriesList?.map((elm) => ({id: elm?.id, store_ids: [""]}));


    useEffect(() => {
        if(GetOnlyAccessoriesProduct !== undefined) {
            SetOptionsProduct(GetOnlyAccessoriesProduct?.map((item, index)=>({...item, key:item.id, value:item?.id , label:item?.product_name })));
        };
    }, [ OrderReportDetails ]);

    const [ ItemAddState, setItemAddState ] = useState({
        product_id: "",
        Quantity: "",
    });
    
    const totalpartialArrayQuantity = calculateTotalQuantityMain(partialArray);

    // Function to calculate total quantity
    function calculateTotalQuantity(items) {
        if(items !== undefined) {
            let totalQuantity = 0;
            for (let i = 0; i < items.length; i++) {
                totalQuantity += items[i].qty;
            }
            return totalQuantity;
        }
    };

    // Function to calculate total quantity
    function calculateTotalQuantityMain(arrayOfObjects) {
        let totalQuantity = 0;

        // Iterate over the outer array
        arrayOfObjects.forEach(obj => {
            // Iterate over the inner array of each object
            obj.items.forEach(item => {
                // Add quantity of each item to totalQuantity
                totalQuantity += parseInt(item.qty);
            });
        });

        return totalQuantity;
    };

    const BoxValueSelect = (value) => {
        if(value !== null) {
            setSelectBox(value);
        } else {
            setSelectBox("");
        }
    };

    function generateRandomId() {
        // Generate a random number
        const randomNumber = Math.floor(Math.random() * 10000);
        
        // Get the current timestamp
        const timestamp = new Date().getTime();
        
        // Concatenate the random number and timestamp to create the ID
        const randomId = `${timestamp}-${randomNumber}`;
        
        return randomId;
    };

    const partialtempArray = [...partialArray];
    const AddBoxFunction = () => {
        const obj = {
            parcel_id: generateRandomId(),
            box_id: selectBox,
            box_weight: "",
            items: []
        };
        partialtempArray?.push(obj);
        setpartialArray(partialtempArray);
        setSelectBox("");
    };

    // product selection
    const ProductSelection = (value) => {
        if(value !== null) {
            setItemAddState({...ItemAddState, product_id: value});
        } else {
            setItemAddState({...ItemAddState, product_id: ""});
        };
    };

    let updatedData = [...partialArray];
    // Function to add a new subItem to an item by ID
    const addSubItemById = (id, newSubItem) => {
        const itemIndex = partialArray.findIndex(item => item.parcel_id === id);
        if (itemIndex !== -1) {
            updatedData[itemIndex].items.push(newSubItem);
            let mergedArray = mergeAndUpdate(updatedData, id);
            const updatedDataArray = updatedData?.map((elm) => {
                if(elm?.parcel_id === id) {
                    return{
                        ...elm,
                        items: mergedArray,
                    }
                } else {
                    return elm;
                };
            });
            
            setpartialArray(updatedDataArray);
        } else {
            console.log('Item not found with id:', id);
        }
    };

    // merge array and add quantity
    function mergeAndUpdate(arr, id) {
        let updatedItems = {};
        arr.forEach(obj => {
            if(obj?.parcel_id === id) {
                obj.items.forEach(item => {
                    const productId = item.order_item_id;
                    if (!updatedItems[productId]) {
                        updatedItems[productId] = { ...item };
                    } else {
                        updatedItems[productId].qty += parseInt(item.qty);
                    }
                });
            };
        });
       
        let updatedData = Object.keys(updatedItems).map(productId => {
            return {
                id: updatedItems[productId].id,
                order_item_id: productId,
                qty: updatedItems[productId].qty,
                product_id: updatedItems[productId].product_id
            };
        });
        
        return updatedData;
    };
   
    // create new box modal
    const AddNewBoxModal = () => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: "Create New Box",
            subtitle: "Create New Box",
            modalType: "form",
            button: "Create"
        })
    };

    const PartialArrayTotalQuantity = calculateProductTotalQuantity(partialArray);

    const AddProductItemFunction = (data) => {
        if(ItemAddState?.Quantity !== "" && ItemAddState?.product_id !== "") {
            const Checkqnty = optionsProduct?.filter((elm) => {
                if(ItemAddState?.product_id?.id === elm?.id) {
                    if(PartialArrayTotalQuantity[elm?.id] === undefined) {
                        return parseInt(ItemAddState?.Quantity) <= elm?.qty;    
                    } else {
                        return (parseInt(ItemAddState?.Quantity) + parseInt(PartialArrayTotalQuantity[elm?.id])) <= elm?.qty;
                    }
                }
            })

            if(Checkqnty?.length > 0) {
                const Objd = {
                    id: generateRandomId(),
                    order_item_id:  ItemAddState?.product_id?.id,
                    product_id: ItemAddState?.product_id,
                    qty: parseInt(ItemAddState?.Quantity),
                };
                addSubItemById(data?.parcel_id, Objd);
    
                setItemAddState({
                    ...ItemAddState,
                    product_id: "",
                    Quantity: "",
                });
            } else {
                return toast.error("The product quantity should not be greater than the actual product quantity.");
            }
        };
    };

    const PartialArrayUpdate = [... partialArray];
    // update box weight total
    const UpdateBoxTotalWeight = (value, data_) => {
        const PartialArrayUpdateMain = PartialArrayUpdate?.map((elm) => {
            if(elm?.parcel_id === data_?.parcel_id) {
                return{
                    ...elm,
                    box_weight: value,
                }
            } else 
            return elm;
        });
        
        setpartialArray(PartialArrayUpdateMain);
    };

    // delete item
    const deleteItem = (elm, elm_item) => {
        const partialArraysss = partialArray.map((obj) => {
            if(elm?.parcel_id === elm?.parcel_id) {
                return({
                    ...obj,
                    items: obj.items.filter(item => item.id !== elm_item?.id)
                })
            };
        });
        setpartialArray(partialArraysss);
    };

    
    const [ optionsProductNew, setoptionsProductNew ] = useState([]);

    useEffect(() => {
        setoptionsProductNew(() => optionsProduct?.filter((elm) => {
            if(PartialArrayTotalQuantity[elm?.id] !== parseInt(elm?.qty)) {
                return elm;
            };
        }))
    }, [ optionsProduct, partialArray ]);

    // Function to calculate total quantity
    function calculateProductTotalQuantity(arrayOfObjects) {
        let productQuantities = {};
        
        arrayOfObjects.forEach(obj => {
            // Iterate through the products array in each object
            obj.items.forEach(product => {
                const { order_item_id, qty } = product;
                // Check if the product ID already exists in the productQuantities object
                if (productQuantities[order_item_id]) {
                    // If it exists, add the quantity to the existing quantity
                    productQuantities[order_item_id] += qty;
                } else {
                    // If it doesn't exist, create a new entry with the quantity
                    productQuantities[order_item_id] = qty;
                }
            });
        });

        return productQuantities;
    };

    
    const CheckTotalWieght = partialArray?.filter((elm) => elm?.box_weight === "")?.length;
    const CheckTotalProductArray = partialArray?.filter((elm) => elm?.items?.length === 0)?.length;

    const DisableBtn = (url) => {
        let status = true;
        const entyEqual = totalpartialArrayQuantity === totalQuantity;
        if(url === "accesoririeswithBike") {
            if(entyEqual) {
                if(disable || CheckTotalWieght > 0) {
                    status = true;
                }else {
                    status = false;
                };
            } 
            // else {
            //     if(totalQuantity === 1){
            //         status = false
            //     }
            // };
            return status;
        } else {
            // if(totalQuantity === 1){
            //     status =false
            // }
            // else 
            if(entyEqual && CheckTotalWieght === 0 ) {
                status = false;
            } else {
                status = true;
            };
            return status;
        };
    };

    // Function to calculate total quantity
    function calculateTotalProductBox(productBoxArray) {
        let totalboxweight = 0;
        let totalQuantity = 0;
       
        // Iterate over the outer array
        productBoxArray.forEach(obj => {
           totalboxweight += parseFloat(obj.product_id.product.box_weight);
           totalQuantity += parseInt(obj?.qty);
        });

        return totalboxweight * totalQuantity;
    };
    
    document.addEventListener("wheel", function(event){
        if(document.activeElement.type === "number" && document.activeElement.classList.contains("inputBoxs")) {
            document.activeElement.blur();
        }
    });

    // common function
    const CommonFunction = async (id) => {
        // const printContents = document.getElementById(id).innerHTML;
        // const originalContents = document.body.innerHTML;
        // document.body.innerHTML = printContents;
        // window.print();
        // document.body.innerHTML = originalContents;
        const printContent = document.getElementById(id);
        const newWindow = window.open('', '_blank', "width=1200,height=600");
        newWindow.document.write(`
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>New Window</title>
            <!-- Add Bootstrap CSS link -->
            <style>
                table.printTable {
                    border-collapse: collapse;
                }
                .printTable th, .printTable td {
                    border: 1px solid black;
                    padding: 8px;
                    text-align: left;
                }
                .printTable th {
                    background-color: #f2f2f2;
                }
                .sotklevelbtn {
                    display: none;
                }
            </style>
        </head>
        <body>
            ${printContent.innerHTML}
        </body>
        </html>`);
        newWindow.document.close();
        newWindow.print();

        // Listen for the print dialog close event
        const printDialogClosed = () => {
            newWindow.close();
            window.removeEventListener('focus', printDialogClosed);
        };
    
        // Event listener to handle print dialog close
        window.addEventListener('focus', printDialogClosed);
    };

    return(
        <React.Fragment>
            <section className="Main_section">
                <div className="MainHeader">
                    <button  className='btn btn-icon p-1' id="print_btn_function" onClick={() => CommonFunction("contentToPrint")}>
                        <i className="bi bi-printer"></i>
                    </button>
                    <Link className="btn btn-link backbtn" to={FBA_ORDER_LIST} onClick={() => Dispatch(GetOrderReportDetails({}))}><i className="bi bi-arrow-left-short"></i> Back</Link>
                </div>
                    <Scrollbars
                        style={{ height: "calc(100vh - 149px )"}} 
                        renderView={props => <div {...props} className="view"/>}
                        
                        className="ScrollbarsSidebar"
                    >
                        <div className="OrderDetailsModal" id="OrderDetailsModalNew">
                            <div >
                                <h5>{OrderViewerModal?.Title}</h5>
                            </div>
                            {/* print content */}
                            <div className="orderDetailsViewr" id="contentToPrint" style={{ display: "none" }}>
                                {OrderReportDetails !== undefined && Object.keys(OrderReportDetails)?.length > 0 ? (<div>
                                    <PurchaseOrderReport  OrderReportDetails={OrderReportDetails}/>
                                </div>) : (<>
                                    Please Wait...
                                </>)}
                            </div>

                            <div className="orderDetailsViewr">
                                {OrderReportDetails !== undefined && Object.keys(OrderReportDetails)?.length > 0 ? (<div>
                                    <PurchaseOrderReport OrderReportDetails={OrderReportDetails}/>
                                </div>) : (<>
                                    Please Wait...
                                </>)}
                            </div>
                        </div>
                    </Scrollbars>
            </section>

            {MainLoader && (<CommonLoader />)}
        </React.Fragment>
    )
}

export default OrderDetailsViewer;