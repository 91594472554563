/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Collapse, Image, Button } from 'react-bootstrap';
import DataTableFilter from "../Common/DataTableFilter";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { WebSocketContext } from "../../App";
import { ClearFormSetFutios } from "../../redux/actions/adminActions";
import wsSend_request from "../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonPagination from "../Common/CommonPagination";
import GetCurrentTableSRNo from "../Common/GetCurrentTableSRNo";
import CommonDateTime from "../Common/CommonDateTime";
import MoreIcon from '../../assets/img/more_options.png';
import ZohoRetryAPI from "../../Api/ZohoRetryAPI";
import CommonLogout from "../Common/CommonLogout";
import { toast } from "react-toastify";
import CommonLoader from "../Common/CommonLoader";

const Tickets = (props) => {
    const { RouteName } = props;
    const { ticketsList, device_id, selfInfo, accessToken } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const Dispatch = useDispatch();
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ activeTableDetails, setActiveTableDetails ] = useState("");
    const [ Loader, setLoader ] = useState(false);
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // show more user details
    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // hide more user details
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // api calling
    const APICall = () => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.DealerApp[2]?.Edit || PermissionsAccess?.DealerApp[2]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "ticket_list",
                "request" : { 
                    "limit" : userLimit,
                    "page_no" : currentPage, 
                    "search" : userSearch?.trim(),
                    "order_by" :"-updated_at"
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket, userSearch, userType, currentPage, userLimit ]);

    // Retry ERP order
    const RtryFunction = async (id) => {
        setLoader(true);
        const Response = await ZohoRetryAPI(id, accessToken);
        if(Response?.status === 200) {
            setLoader(false);
            APICall();
            toast.success(Response?.message);
        } else {
            setLoader(false);
            APICall();
            if(Response?.error) {
                toast.error(Response?.error);
            } else {
                toast.error(Response?.message);
            };
            if(Response?.message === "User is not authorized." && Response?.status === 400) {
                CommonLogout(accessToken, Dispatch);
                localStorage?.removeItem("access_token");
            };
        };
    };

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
    }, []);

    return(<section className="Main_section">
        <DataTableFilter 
            filterType={""}
            searchType={""}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={false}
            searchdisable={true}
            ActiveInactiveFilter={false}
        />
        <div className="tableView">
            <Scrollbars 
                style={{ height: ticketsList?.pagination?.total_records > 10 ? "calc(100vh - 186px)" : "calc(100vh - 205px)"}} 
                renderView={props => <div {...props} className="view"/>}
                className="ScrollbarsSidebar"
            >
                <Table bordered  >
                    <thead>
                        <tr>
                            <th></th>
                            <th>SNO</th>
                            <th>Ticket Number</th>
                            <th>Full Name</th>
                            <th>Email</th>
                            <th>Frame Number</th>
                            <th>Dealer Name</th>
                            <th>Status</th>
                            <th>Zoho Error</th>
                            {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.DealerApp[2]?.Edit) && (<th>Action</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {ticketsList?.list?.map((elm, index) => {
                            return(<React.Fragment key={index}>
                            <tr key={index}>
                            <td style={{ width: '10px' }}>
                                        <button 
                                            className={activeTableDetails === elm?.id ? "btn actionbtn active" : "btn actionbtn"}
                                            onClick={() => activeTableDetails === elm?.id ? ElseMoreDetails() : ViewMoreDetails(elm?.id)}
                                            aria-controls="example-collapse-text"
                                            aria-expanded={activeTableDetails === elm?.id ? true : false}
                                        >
                                            <Image src={MoreIcon} alt="more icon" />
                                        </button>
                                    </td>
                                <td>
                                    {GetCurrentTableSRNo(index, ticketsList?.pagination?.current_page, ticketsList?.pagination?.record_limit)}    
                                </td>
                                <td>{elm?.ticket_number === null ? "-" : elm?.ticket_number}</td>
                                <td>{elm?.full_name}</td>
                                <td>{elm?.email}</td>
                                <td>{elm?.frame_number}</td>
                                <td>{elm?.dealer_name}</td>
                                <td>{elm?.status}</td>
                                <td>{elm?.zoho_error === null ? "-" : elm?.zoho_error}</td>
                                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.DealerApp[2]?.Edit) && (<td>
                                    <Button className="btn edittable edittableretry btn btn-primary" onClick={() => RtryFunction(elm?.id)}>
                                        <i className="bi bi-arrow-repeat"></i> Retry
                                    </Button>
                                </td>)}
                            </tr>
                            <Collapse in={activeTableDetails === elm?.id ? true : false}>
                                <tr className="tablecollapsewraps" id={"tablecollpase"+elm?.id}>
                                    <td colSpan="9" >
                                        <Table bordered  className="table">
                                            <tbody>
                                                <tr>
                                                    <th style={{ width: "25%" }}>Created At</th>
                                                    <td>{CommonDateTime(elm.created_at, "LL", "show")}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "25%" }}>Mobile</th>
                                                    <td>{elm?.mobile}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "25%" }}>Address</th>
                                                    <td>{elm?.address}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "25%" }}>Description</th>
                                                    <td>{elm?.description}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </td>
                                </tr>
                            </Collapse>
                            </React.Fragment>)
                        })}
                        {ticketsList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="10">Tickets list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {/* pagination */}
            {ticketsList?.pagination?.total_records > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={ticketsList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>

        {Loader && (<CommonLoader />)}
    </section>)
}

export default Tickets;