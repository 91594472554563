/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Button, Form, Table } from 'react-bootstrap';
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { INCENTIVE_SLAB_URL } from "../../Shared/constant";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import CheckValid from "../../Common/CheckValid";
import { useSelector, useDispatch } from "react-redux";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";

const AddIncentiveSlab = () => {
    const Dispatch = useDispatch();
    const Navigate = useNavigate();
    const LocationRoute = useLocation()?.pathname?.split("/")[1];
    const { websocket } = useContext(WebSocketContext);
    const [ ApplicationState, setApplicationState ] = useState({
        incentive_title: "",
        slabs: [],
        Status: true
    });
    const [ RangeState, setRangeState ] = useState({
        range_from: "",
        range_to: "",
        amount: "",
    });
    const { ClearFormSet, device_id, IncentiveSlabList } = useSelector((state) => state.adminReducers);
    const exceptThisCostSymbols = ["e", "E", "+", "-"];

    // error state
    const [errorincentive_title, seterrorincentive_title] = useState("");
    const [errorRangeValue, seterrorRangeValue] = useState("");

    const { roomId } = useParams();
    const CurrentData_ = IncentiveSlabList?.list?.filter((elm) => elm?.incentive_code === roomId);

    // update incentive groups into list
    const UpdateIncentiveSlabList = Object.values(
        CurrentData_?.reduce((acc, { title, incentive_code, slab, range_from, range_to, amount }) => {
            if (!acc[incentive_code]) {
                acc[incentive_code] = {
                    title,
                    incentive_code,
                    incentives: [] 
                };
            };
            
            // Push slab details to the incentives array
            acc[incentive_code].incentives.push({ slab, range_from, range_to, amount });
            
            return acc;
        }, {})
    );

    useEffect(() => {
        if(LocationRoute === "edit-incentive-slab") {
            const current_data_new = UpdateIncentiveSlabList[0]
            setApplicationState({
                ...ApplicationState,
                incentive_title: current_data_new?.title,
                slabs: current_data_new?.incentives,
            });
        };
    }, [ ]);

    // range validation 
    const RangeAddValidations = (value, url) => {
        setRangeState({ 
            ...RangeState, 
            [url]: value,
        });
    };

    function generateRandomId() {
        // Generate a random number
        const randomNumber = Math.floor(Math.random() * 10000);
        
        // Get the current timestamp
        const timestamp = new Date().getTime();
        
        // Concatenate the random number and timestamp to create the ID
        const randomId = `${timestamp}-${randomNumber}`;
        
        return randomId;
    };

    // Add slabs function with range validation
    let slabsvar = [...ApplicationState?.slabs];
    const AddRangeFunction = () => {
        const newRangeFrom = parseInt(RangeState.range_from, 10);
        const newRangeTo = parseInt(RangeState.range_to, 10);

        // Check if the new range overlaps with any existing range
        const isOverlap = slabsvar.some(slab => {
            const existingFrom = parseInt(slab.range_from, 10);
            const existingTo = parseInt(slab.range_to, 10);
            return (
                (newRangeFrom >= existingFrom && newRangeFrom <= existingTo) ||
                (newRangeTo >= existingFrom && newRangeTo <= existingTo) ||
                (newRangeFrom <= existingFrom && newRangeTo >= existingTo)
            );
        });

        if (isOverlap) {
            seterrorRangeValue("Error: The Range From and Range To values must not overlap with existing slabs.");
            return; // Prevent adding the range
        }

        // Proceed to add the range if no overlap is found
        let rangObj = {
            id: generateRandomId(),
            range_from: RangeState?.range_from,
            range_to: RangeState?.range_to,
            amount: RangeState?.amount,
        };

        slabsvar.push(rangObj);
        seterrorRangeValue("");
        setRangeState({
            ...RangeState,
            range_from: "",
            range_to: "",
            amount: "",
        });

        const updateSlbIndex = slabsvar.map((elm, index) => ({
            ...elm,
            slab: index + 1,
        }));

        setApplicationState({
            ...ApplicationState,
            slabs: updateSlbIndex,
        });
    };

    // create Incentives Slabs
    const CreateIncentivesSlabs = () => {
        if(ApplicationState?.incentive_title !== "" && ApplicationState?.slabs?.length > 0) {
            Dispatch(ClearFormSetFutios({
                url: "incentive_slab_add",
                action: true
            }));
            let param = { 
                "transmit": "broadcast", 
                "url": "incentive_slab_add",
                "request" : { 
                    "incentive_title" : ApplicationState?.incentive_title,
                    "incentive_code" : "",
                    "is_active" : ApplicationState?.Status,
                    "slabs" : ApplicationState?.slabs
                },
                "DeviceId" : device_id
            };
            if(LocationRoute === "edit-incentive-slab") {
                param.request.incentive_code = UpdateIncentiveSlabList[0].incentive_code
            }
            wsSend_request(websocket, param);
        } else {
            CheckValid(ApplicationState?.incentive_title, { type: 'IncentiveTitle', error: seterrorincentive_title })
            if(ApplicationState?.slabs?.length === 0) {
                seterrorRangeValue("Please add incentive slab");
            };
        };
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200 && ClearFormSet?.url === "incentive_slab_add") {
            Navigate(INCENTIVE_SLAB_URL);
        };
    }, [ ClearFormSet ]);

    return(<section className="createApplcation_wrapper createScheme_wrapper">
        <div className="MainHeader">
            <Link to={INCENTIVE_SLAB_URL} className="btn btn-link backbtn"><i className="bi bi-arrow-left-short"></i> Back</Link>
        </div>
        <Scrollbars 
            style={{ height: "calc(100vh - 147px)"}} 
            renderView={props => <div {...props} className="view"/>}
            className="ScrollbarsSidebar"
        >
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-4">
                        <Form.Group className="mb-3" >
                            <Form.Label>Incentive Title <span className="mendatory_star">*</span></Form.Label>
                            <Form.Control
                                type="text"
                                value={ApplicationState?.incentive_title}
                                onChange={(e) => setApplicationState({ ...ApplicationState, incentive_title: e.target.value })}
                                onKeyUp={(e) => CheckValid(e.target.value, { type: 'IncentiveTitle', error: seterrorincentive_title })}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter Incentive Title"
                            />
                            {errorincentive_title !== "" && <span className="error_medotory">{errorincentive_title}</span>}
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="HeaderDivider">
                            <h6 className="subtitleForm">
                                Add Slab Range:
                            </h6>
                            <div className="row">
                                <div className="col-md-3">
                                    <Form.Group>
                                        <Form.Label>Range From<span className="mendatory_star">*</span></Form.Label>
                                        <Form.Control 
                                            type="number" 
                                            className="inputBoxs"
                                            value={RangeState?.range_from} 
                                            onChange={(e) => RangeAddValidations(e.target.value.replace(/^0/, ""), "range_from")}
                                            onKeyDown={(e) => {
                                                EmptySpaceFieldValid(e)
                                                exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                            }}
                                            placeholder="Enter Range From" 
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-md-3">
                                    <Form.Group>
                                        <Form.Label>Range To<span className="mendatory_star">*</span></Form.Label>
                                        <Form.Control 
                                            type="number" 
                                            className="inputBoxs"
                                            value={RangeState?.range_to} 
                                            onChange={(e) => RangeAddValidations(e.target.value.replace(/^0/, ""), "range_to")}
                                            onKeyDown={(e) => {
                                                EmptySpaceFieldValid(e)
                                                exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                            }}
                                            placeholder="Enter Range To" 
                                            // min="0"
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-md-3">
                                    <Form.Group>
                                        <Form.Label>Amount<span className="mendatory_star">*</span></Form.Label>
                                        <Form.Control 
                                            type="number" 
                                            className="inputBoxs"
                                            value={RangeState?.amount} 
                                            onChange={(e) => setRangeState({...RangeState, amount: e?.target?.value.replace(/^0/, "")})}
                                            onKeyDown={(e) => {
                                                EmptySpaceFieldValid(e)
                                                exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                            }}
                                            placeholder="Enter Amount" 
                                            min="0"
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-md-3">
                                    <Form.Group>
                                        <Form.Label>&nbsp;</Form.Label>
                                        <Button className="addRangebtn" style={{ width: "auto" }} onClick={() => AddRangeFunction()} disabled={RangeState?.range_from === "" || RangeState?.range_to === "" || RangeState?.amount === ""}>Add Range</Button>
                                    </Form.Group>
                                </div>
                                <div className="col-md-12 mb-3">
                                    {errorRangeValue !== "" && <span className="error_medotory">{errorRangeValue}</span>}
                                </div>
                            </div>
                            {ApplicationState?.slabs?.length > 0 && (<div className="row">
                                <div className="col-md-12">
                                    <div className="tableView">
                                        <Table bordered  >
                                            <thead>
                                                <tr>
                                                    <th>Slab</th>
                                                    <th>Range From</th>
                                                    <th>Range to</th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {ApplicationState?.slabs?.map((elm, index) => {
                                                    return(<tr key={index}>
                                                        <td>{elm?.slab}</td>
                                                        <td>{elm?.range_from}</td>
                                                        <td>{elm?.range_to}</td>
                                                        <td>{elm?.amount}</td>
                                                    </tr>)
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>)}
                        </div>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-md-12">
                        <Button 
                            className="formbtn widthfullbtn" 
                            disabled={ClearFormSet?.action === true && ClearFormSet?.url === "incentive_slab_add"}
                            onClick={() => CreateIncentivesSlabs()} 
                            variant="primary" 
                            style={{ width: "auto" }} 
                        >
                            {LocationRoute === "edit-incentive-slab" ? "Update" : "Add"}
                        </Button>
                    </div>
                </div>
            </div>
        </Scrollbars>
    </section>)
}

export default AddIncentiveSlab;