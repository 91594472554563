/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Form, Badge } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import OrderDataTableFilter from "../../Common/OrderDataTableFilter";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";
import CommonDateTime from "../../Common/CommonDateTime";
import CommonPagination from "../../Common/CommonPagination";
import { getTableSorting, updateOrderManagementFilter } from "../../../redux/actions/adminActions";
import { Link } from "react-router-dom";
// import CommonExport from "../../Common/CommonExport";
import { TimeSense } from "../../Common/TimeSense";
import { AFTER_SALES_ORDERS_URL } from "../../Shared/constant";

const AfterSalesOrders = (props) => {
    const { RouteName } = props;
    const Dispatch = useDispatch();
    const [userType, setUserType] = useState("all");
    const { websocket } = useContext(WebSocketContext);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const today = new Date();
    const { device_id, AfterSalesOrderList,selfInfo, AllDealerFranchiseTypeListAll, OrderManagmentFilter, sortingfor, Regionlistall } = useSelector((state) => state.adminReducers);
    const [ FilterOpen, setFilterOpen ] = useState(false);
    const PermissionsAccess = selfInfo?.user?.permissions_access;
    
    //Order model
    const [OrderNewModel, setOrderNewModel] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });

    useEffect(() => {
        if(OrderManagmentFilter?.AfterSalesOrders?.WarehouseName !== "" && OrderManagmentFilter?.AfterSalesOrders?.WarehouseName !== undefined) {
            setFilterOpen(true);
        } else {
            setFilterOpen(false);
        };
    }, []);

    //order status
    const showOrder = (value) => {
        setOrderNewModel({
            ...OrderNewModel,
            open: !OrderNewModel?.open,
            title: "Order Log",
            subtitle: "",
            Data: value
        });
    };

    // page change
    const currentFunction = (page) => {
        Dispatch(updateOrderManagementFilter({
            AfterSalesOrders: {
                CurrentPage: page,
            }
        }));
    };

    useEffect(() => {
        CommonSortingFct("")
    }, []);

    // get all region list
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "region_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        Dispatch(updateOrderManagementFilter({
            AfterSalesOrders: {
                CurrentPage: 1,
            }
        }));
    };

    const selectRegionFunction = (e) => {
        Dispatch(updateOrderManagementFilter({
            AfterSalesOrders: {
                SelectRegion: e.target.value,
                CurrentPage: 1,
            }
        }));
    };

    // select start date
    const DateStartGet = (date) => {
        if (date === null) {
            setStartDate("");
            setEndDate("");
            Dispatch(updateOrderManagementFilter({
                AfterSalesOrders: {
                    FromDate: "",
                    ToDate: "",
                }
            }));
        } else {
            Dispatch(updateOrderManagementFilter({
                AfterSalesOrders: {
                    FromDate: date,
                }
            }));
            let selectedDate = new Date(date);
            if (selectedDate.toDateString() === today.toDateString()) {
                setStartDate(selectedDate);
                setEndDate(selectedDate);
            } else if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
        }
    };

    // select end date
    const DateEndGet = (date) => {
        Dispatch(updateOrderManagementFilter({
            AfterSalesOrders: {
                CurrentPage: 1,
            }
        }));
        if (date === null) {
            setEndDate("")
            let selectedDate = new Date(date);
            if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
            Dispatch(updateOrderManagementFilter({
                AfterSalesOrders: {
                    ToDate: "",
                }
            }));
        } else {
            Dispatch(updateOrderManagementFilter({
                AfterSalesOrders: {
                    ToDate: date,
                }
            }));
        }
    };

    // get order_list all
    useEffect(() => {
        const { UserLimit, UserSearch, SelectRegion, FromDate, ToDate, CurrentPage } = OrderManagmentFilter?.AfterSalesOrders;

        let param = {
            "transmit": "broadcast",
            "url": "afs_order_list",
            "request": {
                "status": "OPEN",
                "limit": UserLimit,
                "page_no": CurrentPage,
                "search": UserSearch.trim(),
                "from_date": null,
                "to_date": null,
                "from_inv": null,
                "to_inv": null,
                "region_id": SelectRegion,
                "order_by": sortingfor,
            },
            "DeviceId": device_id
        };
        if (FromDate !== "" && ToDate !== "") {
            param.request.from_date = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
            param.request.to_date = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
        }
        wsSend_request(websocket, param);
    }, [ websocket, sortingfor, OrderManagmentFilter?.AfterSalesOrders ]);

    const GetFranchiseName = (billing_id) => {
        const current = AllDealerFranchiseTypeListAll?.list?.filter((elm) => elm?.id === billing_id)[0]
        return {
            name: current?.customer_name
        };
    };

    const CommonSortingFct = (url) => {
        if (sortingfor === url) {
            Dispatch(getTableSorting("-" + url))
        } else {
            Dispatch(getTableSorting(url))
        };
    };

    return (<section className="Main_section">
        <div className="filtr_Form" style={{ paddingBottom: "10px", borderBottom: "1px solid #eee", marginBottom: "10px" }}>
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-3" style={{ width: "23%" }}>
                        <Form.Group>
                            <div className="datepickField min">
                                <DatePicker
                                    selected={OrderManagmentFilter?.AfterSalesOrders?.FromDate === "" ? "" : new Date(OrderManagmentFilter?.AfterSalesOrders?.FromDate)}
                                    startDate={OrderManagmentFilter?.AfterSalesOrders?.FromDate === "" ? "" : new Date(OrderManagmentFilter?.AfterSalesOrders?.FromDate)}
                                    endDate={OrderManagmentFilter?.AfterSalesOrders?.ToDate === "" ? "" : new Date(OrderManagmentFilter?.AfterSalesOrders?.ToDate)}
                                    onChange={(date) => DateStartGet(date)}
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={today}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter From Date"
                                    isClearable={OrderManagmentFilter?.AfterSalesOrders?.FromDate !== "" ? true : false}
                                    name="statDate"
                                    autoComplete="off"
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3" style={{ width: "23%" }}>
                        <Form.Group>
                            <div className="datepickField">
                                <DatePicker
                                    selected={OrderManagmentFilter?.AfterSalesOrders?.ToDate === "" ? "" : new Date(OrderManagmentFilter?.AfterSalesOrders?.ToDate)}
                                    startDate={OrderManagmentFilter?.AfterSalesOrders?.FromDate === "" ? "" : new Date(OrderManagmentFilter?.AfterSalesOrders?.FromDate)}
                                    endDate={OrderManagmentFilter?.AfterSalesOrders?.ToDate === "" ? "" : new Date(OrderManagmentFilter?.AfterSalesOrders?.ToDate)}
                                    minDate={startDate}
                                    maxDate={endDate}
                                    onChange={(date) => DateEndGet(date)}
                                    dateFormat="dd/MM/yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter To Date"
                                    isClearable={OrderManagmentFilter?.AfterSalesOrders?.ToDate !== "" ? true : false}
                                    name="ToDate"
                                    autoComplete="off"
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3" style={{ width: "15%" }}>
                        <select className="form-control activestatusselet pe-4" id="columnfullwith" value={OrderManagmentFilter?.AfterSalesOrders?.SelectRegion} onChange={(e) => selectRegionFunction(e)}>
                            <option value="">Select Region</option>
                            {Regionlistall?.map((elm, index) => {
                                return(<option value={elm?.id} key={index}>{elm?.region_name}</option>)
                            })}
                        </select>
                    </div>
                    {/* <div className="col-md-3" style={{ width: "auto" }}>
                        <button className="btn exportbtn" id="exportbtn" disabled={OrderManagmentFilter?.AfterSalesOrders?.ToDate === "" || OrderManagmentFilter?.AfterSalesOrders?.FromDate === ""} style={{ width: "auto", marginRight: "0px" }} onClick={() => CommonExport("order_list", { "FromDate": OrderManagmentFilter?.AfterSalesOrders?.FromDate, "ToDate": OrderManagmentFilter?.AfterSalesOrders?.ToDate, "FromInv": null, "ToInv": null, "status": OrderManagmentFilter?.AfterSalesOrders?.SelectOrderType, "region_id": OrderManagmentFilter?.AfterSalesOrders?.SelectRegion ,"search": OrderManagmentFilter?.AfterSalesOrders?.UserSearch.trim(), "order_type": orderSource })}>
                            <i className="bi bi-filetype-xlsx"></i>
                            Export
                        </button>
                    </div> */}
                </div>
            </div>
        </div>
        <OrderDataTableFilter
            filterType={""}
            searchType={"Order Id, Customer Code"}
            userType={userType}
            setUserType={setUserType}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={false}
            searchdisable={true}
            ActiveInactiveFilter={false}
        />
        <div className="tableView gstreporttableadded">
            <Scrollbars
                style={{ height: FilterOpen ? AfterSalesOrderList?.pagination?.total_records > 10 ? "calc(100vh - 293px)" : "calc(100vh - 293px)" : AfterSalesOrderList?.pagination?.total_records > 10 ? "calc(100vh - 245px)" : "calc(100vh - 209px)" }}
                renderView={props => <div {...props} className="view" />}
                className="ScrollbarsSidebar"
            >
                <Table bordered  >
                    <thead>
                        <tr>
                            <th>SNo</th>
                            <th className={sortingfor === "order_code" ? "active" : sortingfor === "-order_code" ? "inactive" : ""} onClick={() => CommonSortingFct("order_code")}>Order Id.</th>
                            <th className={sortingfor === "customer_identity" ? "active" : sortingfor === "-customer_identity" ? "inactive" : ""} onClick={() => CommonSortingFct("customer_identity")}>Customer Code</th>
                            <th className={sortingfor === "created_at" ? "active" : sortingfor === "-created_at" ? "inactive" : ""} onClick={() => CommonSortingFct("created_at")}>Order Date</th>
                            <th className={sortingfor === "updated_at" ? "active" : sortingfor === "-updated_at" ? "inactive" : ""} onClick={() => CommonSortingFct("updated_at")}>Updated Date</th>
                            <th className={sortingfor === "order_source" ? "active" : sortingfor === "-order_source" ? "inactive" : ""} onClick={() => CommonSortingFct("order_source")}>Order Source</th>
                            <th className={sortingfor === "customer_name" ? "active" : sortingfor === "-customer_name" ? "inactive" : ""} onClick={() => CommonSortingFct("customer_name")}>SHIP To Name</th>
                            <th className={sortingfor === "billing_id" ? "active" : sortingfor === "-billing_id" ? "inactive" : ""} onClick={() => CommonSortingFct("billing_id")}>BILL To Name</th>
                            <th>Ship From Warehouse</th>
                            <th className={sortingfor === "erp_invoice_code" ? "active" : sortingfor === "-erp_invoice_code" ? "inactive" : ""} onClick={() => CommonSortingFct("erp_invoice_code")}>Invoice No.</th>
                            <th className={sortingfor === "invoice_at" ? "active" : sortingfor === "-invoice_at" ? "inactive" : ""} onClick={() => CommonSortingFct("invoice_at")}>Invoice Date</th>
                            <th className={sortingfor === "courier_name" ? "active" : sortingfor === "-courier_name" ? "inactive" : ""} onClick={() => CommonSortingFct("courier_name")}>Transporter</th>
                            <th className={sortingfor === "delivered_date" ? "active" : sortingfor === "-delivered_date" ? "inactive" : ""} onClick={() => CommonSortingFct("delivered_date")}>Delivered Date</th>
                            <th className={sortingfor === "docket_number" ? "active" : sortingfor === "-docket_number" ? "inactive" : ""} onClick={() => CommonSortingFct("docket_number")}>Docket Number</th>
                            <th>ERP Sales Order Code</th>
                            <th>ERP Delivery Note Status</th>
                            <th>ERP Invoice Status</th>
                            <th>Pending Since</th>
                            <th>Remarks</th>
                        </tr>
                    </thead>
                    <tbody>
                        {AfterSalesOrderList?.list?.map((elm, index) => {
                        return (<tr key={index}>
                                <td>
                                    {GetCurrentTableSRNo(index, AfterSalesOrderList?.pagination?.current_page, AfterSalesOrderList?.pagination?.record_limit)}
                                </td>
                                <td>
                                    {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[18]?.Edit) ? (<Link className="btn-link btn-link-color text-decoration-none" to={`${AFTER_SALES_ORDERS_URL}/${elm?.id}`}>
                                        <b className="tableBolanchor">{elm?.order_code}</b>
                                    </Link>) : (<b className="tableBolanchor">{elm?.order_code}</b>)}
                                </td>
                                <td>
                                    {elm?.customer_identity === null ? "-" : elm?.customer_identity}
                                </td>
                                <td>{CommonDateTime(elm?.created_at, "LL", "show")}</td>
                                <td>{CommonDateTime(elm?.updated_at, "LL", "show")}</td>
                                <td>{elm?.order_source}</td>
                                <td>{elm?.customer_name !== null ? elm?.customer_name : "-"}</td>
                                <td>{GetFranchiseName(elm?.billing_id)?.name === undefined ? "-" : GetFranchiseName(elm?.billing_id)?.name}</td>
                                <td>{elm?.warehouse_name === null ? "-" : elm?.warehouse_name}</td>
                                <td>
                                    {/* {selfInfo?.user?.user_type === 'super_admin' ? (<>
                                        {!elm?.erp_invoice_code ? "-" : (<b className="tableBolanchor" onClick={(e) => callInvoiceModal(e, elm?.id)}>{elm?.erp_invoice_code}</b>)}
                                    </>) : (<>
                                        {elm?.order_status === "AC_APPROVED" ? (<b className="tableBolanchor" style={{ cursor: "default" }} >{elm?.erp_invoice_code}</b>) : (<React.Fragment>
                                            {!elm?.erp_invoice_code ? "-" : (<b className="tableBolanchor" onClick={(e) => callInvoiceModal(e, elm?.id)}>{elm?.erp_invoice_code}</b>)}
                                        </React.Fragment>)}
                                    </>)} */}
                                    <b className="tableBolanchor" style={{ cursor: "default" }} >{elm?.erp_invoice_code}</b>
                                </td>
                                <td>{CommonDateTime(elm?.invoice_at || elm?.updated_at, "LL", "show")}</td>
                                <td>{elm?.courier_name ? elm?.courier_name : "-"}</td>
                                <td>{CommonDateTime(elm?.delivered_date || elm?.delivered_date, "LL", "show")}</td>
                                <td>{elm?.docket_number ? elm?.docket_number : "-"}</td>
                                <td>{elm?.erp_id === null ? "-" : elm?.erp_id}</td>
                                <td>{(elm?.erp_delivery_code === null || elm?.erp_delivery_code === "") ? (<Badge bg="danger">Failed</Badge>) : (<Badge bg="success">Success</Badge>)}</td>
                                <td>
                                    {(elm?.erp_invoice_code === null || elm?.erp_invoice_code === "") ? (<Badge bg="danger">Failed</Badge>) : (<Badge bg="success">Success</Badge>)}
                                </td>
                                <td>{TimeSense(elm?.updated_at)}</td>
                                <td>{elm?.remark === null ? "-" : elm?.remark}</td>
                            </tr>)
                        })}
                        {AfterSalesOrderList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="13">After sales order list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {/* pagination */}
            {AfterSalesOrderList?.pagination?.total_records > 10 && (<CommonPagination
                currentPage={OrderManagmentFilter?.AfterSalesOrders?.CurrentPage}
                paginationLength={AfterSalesOrderList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
    </section>)
}

export default AfterSalesOrders;