import { TICKETS_RETRY_API_URL } from "../components/Shared/constant";

const ZohoRetryAPI = async (ticket_id, accessToken) => {
    const responce = window.axios.get(`${TICKETS_RETRY_API_URL}?ticket_id=${ticket_id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + accessToken,
    }}).then(function (result) {
        return {
          ...result.data,
          status:200
        };
    }).catch(function (result) {
        return {
          ...result?.response?.data,
          status:result?.response?.status
        }
    });
    return responce;
};
  
export default ZohoRetryAPI;