/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Form } from 'react-bootstrap';
import DataTableFilter from "../Common/DataTableFilter";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Link, useNavigate } from "react-router-dom";
import { WebSocketContext } from "../../App";
import { ADD_SCHEMES_URL, EDIT_SCHEMES_URL } from "../Shared/constant";
import { ClearFormSetFutios } from "../../redux/actions/adminActions";
import wsSend_request from "../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonPagination from "../Common/CommonPagination";
import GetCurrentTableSRNo from "../Common/GetCurrentTableSRNo";
import CommonDateTime from "../Common/CommonDateTime";
import DeleteModal from "../Common/DeleteModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Schemes = (props) => {
    const { RouteName } = props;
    const { franchiseSchemeList, device_id, selfInfo } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ deleteCommonStateModal, setdeleteCommonStateModal ] = useState({
        title: "",
        description: "",
        open: false,
        delete_id: "",
        delete_url: ""
    });
    const [FromDate, setFromDate] = useState("");
    const [ToDate, setToDate] = useState("");
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const today = new Date();
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };


    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // select start date
    const DateStartGet = (date) => {
        if (date === null) {
            setStartDate("");
            setEndDate("");
            setFromDate("");
            setToDate("");
        } else {
            setFromDate(date)
            let selectedDate = new Date(date);
            if (selectedDate.toDateString() === today.toDateString()) {
                setStartDate(selectedDate);
                setEndDate(selectedDate);
            } else if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
        }
    };

    // select end date
    const DateEndGet = (date) => {
        setCurrentPage(1);
        if (date === null) {
            // setEndDate("")
            let selectedDate = new Date(FromDate);
            if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
            setToDate("")
        } else {
            // setEndDate(date)
            setToDate(date);
        }
    };

    // api calling
    const APICall = () => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.DealerApp[1]?.Edit || PermissionsAccess?.DealerApp[1]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "franchise_scheme_list",
                "request" : { 
                    "status": userType === "active" ? true : userType === "inactive" ? false : "",
                    "limit" : userLimit,
                    "page_no" : currentPage, 
                    "search" : userSearch?.trim(),
                    "start_date": null,
                    "end_date": null,
                    "order_by" :"-updated_at"
                },
                "DeviceId" : device_id
            };
            if (FromDate !== "" && ToDate !== "") {
                param.request.start_date = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
                param.request.end_date = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
            }
            wsSend_request(websocket, param);
        };
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket, userSearch, userType, currentPage, userLimit, FromDate, ToDate ]);

    // delete scheme
    const DeleteSchemes = (data) => {
        setdeleteCommonStateModal({
            ...deleteCommonStateModal,
            title: "<i class='bi bi-trash3' style='color: red'></i> Delete Scheme",
            description: `Are you sure want to delete this scheme ?`,
            open: true,
            delete_id: data?.id,
            delete_url: "scheme-delete"
        });
    };

    // create points
    const cmnFnct = () => {
        Navigate(ADD_SCHEMES_URL);
    };

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
    }, []);

    return(<section className="Main_section">
        <div className="formloginset">
            <div className="row">
                <div className="col-md-3" style={{ width: "23%" }}>
                    <Form.Group className="mb-3">
                        <div className="datepickField min">
                            <DatePicker
                                selected={FromDate}
                                startDate={FromDate}
                                endDate={ToDate}
                                onChange={(date) => DateStartGet(date)}
                                dateFormat="dd/MM/yyyy"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="Enter Start Date"
                                isClearable={FromDate !== "" ? true : false}
                                name="statDate"
                                autoComplete="off"
                            />
                        </div>
                    </Form.Group>
                </div>
                <div className="col-md-3" style={{ width: "23%" }}>
                    <Form.Group className="mb-3">
                        <div className="datepickField">
                            <DatePicker
                                selected={ToDate}
                                startDate={FromDate}
                                endDate={ToDate}
                                minDate={FromDate}
                                onChange={(date) => DateEndGet(date)}
                                dateFormat="dd/MM/yyyy"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="Enter End Date"
                                isClearable={ToDate !== "" ? true : false}
                                name="ToDate"
                                autoComplete="off"
                            />
                        </div>
                    </Form.Group>
                </div>
            </div>
        </div>
        <DataTableFilter 
            filterType={""}
            searchType={"Scheme Name, Incentive Code"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.DealerApp[1]?.Edit) ? true : false}
            ButtonSet={{
                buttontitle: "Create Scheme",
                function: cmnFnct
            }}
            searchdisable={true}
            ActiveInactiveFilter={false}
        />
        <div className="tableView">
            <Scrollbars 
                style={{ height: franchiseSchemeList?.pagination?.total_records > 10 ? "calc(100vh - 240px)" : "calc(100vh - 205px)"}} 
                renderView={props => <div {...props} className="view"/>}
                className="ScrollbarsSidebar"
            >
                <Table bordered  >
                    <thead>
                        <tr>
                            <th>SNO</th>
                            <th>Scheme Name</th>
                            <th>Scheme Code</th>
                            <th>Product Name</th>
                            <th>Incentive Code</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.DealerApp[1]?.Edit) && (<th>Action</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {franchiseSchemeList?.list?.map((elm, index) => {
                            return(<tr key={index}>
                                <td>
                                    {GetCurrentTableSRNo(index, franchiseSchemeList?.pagination?.current_page, franchiseSchemeList?.pagination?.record_limit)}    
                                </td>
                                <td>{elm?.scheme_name}</td>
                                <td>{elm?.scheme_code}</td>
                                <td>{elm?.product_details?.product_name}</td>
                                <td>{elm?.incentive_code}</td>
                                <td>{CommonDateTime(elm.start_date, "LL", "show")}</td>
                                <td>{CommonDateTime(elm.end_date, "LL", "show")}</td>
                                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.DealerApp[1]?.Edit) && (<td>
                                    <Link className="btn edittable" to={`${EDIT_SCHEMES_URL}/${elm?.id}`}>
                                        <i className="bi bi-pencil-square"></i>
                                    </Link>
                                    <button className="btn edittable" style={{ marginLeft: "5px" }} onClick={() => DeleteSchemes(elm)}>
                                        <i className="bi bi-trash3"></i>
                                    </button>
                                </td>)}
                            </tr>)
                        })}
                        {franchiseSchemeList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="7">Schemes list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {/* pagination */}
            {franchiseSchemeList?.pagination?.total_records > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={franchiseSchemeList?.pagination}
                currentFunction={currentFunction}
            />)}

            {/* delete scheme  */}
            {deleteCommonStateModal?.open && (<DeleteModal 
                setdeleteCommonStateModal={setdeleteCommonStateModal} 
                deleteCommonStateModal={deleteCommonStateModal} 
            />)}
        </div>
    </section>)
}

export default Schemes;