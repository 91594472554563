/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Badge, Button, Form, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { PURCHASE_LIST_URL, REGION_MASTER_URL } from "../../Shared/constant";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import CheckValid from "../../Common/CheckValid";
import { useSelector, useDispatch } from "react-redux";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import Select from 'react-select';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { StartMainCommonLoader, StartCommonLoader, SearchPincodeList, ErrorMessageFromRedux, ErrorFramesMessageFromRedux, GetFrameList } from "../../../redux/actions/adminActions";
import { parsePhoneNumber, getRegionCodeForCountryCode } from "awesome-phonenumber";
import UploadFileAPI from "../../../Api/UploadFileAPI";
import CommonToggle from "../../Common/CommonToggle";

const EditPurchase = (props) => {
    const Dispatch = useDispatch();
    const Navigate = useNavigate();
    const LocationRoute = useLocation()?.pathname?.split("/")[1];
    const { websocket } = useContext(WebSocketContext);
    const [UploadFileLoader, setUploadFileLoader] = useState(false);
    const { roomId } = useParams();
    const { accessToken, ClearFormSet, productlistall, AllDealerFranchiseTypeListAll, commonMainLoader, device_id, PurchaseList, commonLoader, ErrorMessageFromReddux, ErrorFramesMessageFromReddux, searchPincodeList, FrameList } = useSelector((state) => state.adminReducers);
    const CurrentData_ = PurchaseList?.list?.filter((elm) => elm?.id === roomId)[0];
    const [ optionsProduct, setoptionsProduct ] = useState(() => productlistall?.filter((elm) => elm?.is_active === true && elm?.is_bike === true)?.map((item, index)=>({key:item.id, value:item?.id , label:item?.product_name})));
    const [ FranchiseListOption, setFranchiseListOption ] = useState(() => AllDealerFranchiseTypeListAll?.list?.filter((elm) => elm?.is_active === true && elm?.parent_id === null)?.map((item, index) => ({ ...item, key: item.id, value: item?.id, label: item?.customer_name })))
    const [ApplicationState, setApplicationState] = useState({
        CustomerName: "",
        Email: "",
        Mobile: "",
        UseCase: "",
        AgeGroup: "",
        PINCode: "",
        ProductValue: "",
        FrameNumber: "",
        ProductName: "",
        // FrameColor: "",
        DealerInfo: "",
        FrameAvailable: false,
    });
    const [Attachment, setAttachment] = useState({
        filename: "",
        fileID: ""
    });
    const [LoaderFile, setLoaderFile] = useState(false);
    const optionsUseCase = [
        { key: 1, value: "Health & Fitness", label: "Health & Fitness" },
        { key: 2, value: "Daily Use - Health", label: "Daily Use - Health" },
        { key: 3, value: "Daily Use - Cost Saving", label: "Daily Use - Cost Saving" },
        { key: 4, value: "Kids", label: "Kids" }
    ];
    const optionsAgeGroups = [
        { key: 1, value: "Below 18", label: "Below 18" },
        { key: 2, value: "18-35", label: "18-35" },
        { key: 3, value: "35 & Above", label: "35 & Above" },
    ];

    // error state
    const [errorCustomerName, seterrorCustomerName] = useState("");
    const [errorMobile, seterrorMobile] = useState("");
    const [errorEmail, seterrorEmail] = useState("");
    const [errorAgeGroup, seterrorAgeGroup] = useState("");
    const [errorPINCode, seterrorPINCode] = useState("");
    const [errorProductName, seterrorProductName] = useState("");
    // const [errorFrameColor, seterrorFrameColor] = useState("");
    const [errorDealerInfo, seterrorDealerInfo] = useState("");
    const [errorUseCase, seterrorUseCase] = useState("");
    const [errorFrameNumber, seterrorFrameNumber] = useState("");
    const [ errorProductValue, seterrorProductValue ] = useState("");

    // error state
    const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/; // eslint-disable-line
    const exceptThisCostSymbols = ["e", "E", "+", "-", "."];

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    // get all product list
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "product_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // get all franchise list
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "franchise_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    useEffect(() => {
        if (LocationRoute === "edit-purchase") {
            const CurrentPincode = PurchaseList?.pin_code.filter((elm) => elm?.id === CurrentData_?.pin_code_id)[0]?.pin_code;
            const CurrentUseCase = optionsUseCase?.filter((elm) => elm?.label === CurrentData_?.use_case)[0];
            const CurrentAgeGroup = optionsAgeGroups?.filter((elm) => elm?.label === CurrentData_?.age_group)[0];
            const CurrentProduct = Object?.keys(FrameList)?.length > 0 ? optionsProduct?.filter((elm) => elm?.value === FrameList?.product?.id)[0] : optionsProduct?.filter((elm) => elm?.value === CurrentData_?.product_id)[0];
            const CurrentDealerInfo = Object?.keys(FrameList)?.length > 0 ? FranchiseListOption?.filter((elm) => elm?.value === FrameList?.franchise?.id)[0] : FranchiseListOption?.filter((elm) => elm?.value === CurrentData_?.franchise_id)[0];
            setTimeout(() => {
                if (CurrentData_?.frame_number !== null) {
                    let param = {
                        "transmit": "broadcast",
                        "url": "get_frame_number",
                        "request": {
                            "frame_number": CurrentData_?.frame_number,
                        },
                        "DeviceId": device_id
                    };
                    wsSend_request(websocket, param);
                } else {
                    Dispatch(StartCommonLoader(false));
                }
                if (CurrentPincode?.length === 6) {
                    let param = {
                        "transmit": "broadcast",
                        "url": "pin_code_search",
                        "request": {
                            "pin_code": CurrentPincode,
                        },
                        "DeviceId": device_id
                    };
                    wsSend_request(websocket, param);
                } else {
                    Dispatch(StartCommonLoader(false));
                }
                setApplicationState({
                    ...ApplicationState,
                    CustomerName: CurrentData_?.customer_name,
                    Email: CurrentData_?.email,
                    Mobile: CurrentData_?.mobile,
                    UseCase: CurrentUseCase,
                    AgeGroup: CurrentAgeGroup,
                    PINCode: CurrentPincode,
                    FrameNumber: CurrentData_?.frame_number,
                    ProductName: CurrentProduct,
                    DealerInfo: CurrentDealerInfo,
                    ProductValue: CurrentData_?.product_value,
                    FrameAvailable: CurrentData_?.frame_available,
                });
                setAttachment({
                    ...Attachment,
                    filename: CurrentData_?.invoice_image_name === null ? "" : CurrentData_?.invoice_image_name,
                    fileID: CurrentData_?.invoice_image === null ? "" : CurrentData_?.invoice_image
                });
            }, 500);
        };
    }, [CurrentData_]);

    useEffect(() => {
        if(Object?.keys(FrameList)?.length > 0) {
            let CurrentProductVar = optionsProduct?.filter((elm) => elm?.value === FrameList?.product?.id)[0];
            const CurrentDealerInfoVar = FranchiseListOption?.filter((elm) => elm?.value === FrameList?.franchise?.id)[0];
            setApplicationState({
                ...ApplicationState,
                ProductName: CurrentProductVar,
                DealerInfo: CurrentDealerInfoVar,
            });
        } else {
            let CurrentProductVar = optionsProduct?.filter((elm) => elm?.value === CurrentData_?.product_id)[0];
            const CurrentDealerInfoVar = FranchiseListOption?.filter((elm) => elm?.value === CurrentData_?.franchise_id)[0];
            setApplicationState({
                ...ApplicationState,
                ProductName: CurrentProductVar,
                DealerInfo: CurrentDealerInfoVar,
            });
        };
    }, [FrameList, CurrentData_]);

    // useEffect(() => {
    //     if (LocationRoute === "edit-purchase") {
    //         const CurrentPincode = PurchaseList?.pin_code.filter((elm) => elm?.id === CurrentData_?.pin_code_id)[0]?.pin_code;
    //         if (CurrentData_?.frame_number !== null) {
    //             let param = {
    //                 "transmit": "broadcast",
    //                 "url": "get_frame_number",
    //                 "request": {
    //                     "frame_number": CurrentData_?.frame_number,
    //                 },
    //                 "DeviceId": device_id
    //             };
    //             wsSend_request(websocket, param);
    //         } else {
    //             Dispatch(StartCommonLoader(false));
    //         }
    //         if (CurrentPincode?.length === 6) {
    //             let param = {
    //                 "transmit": "broadcast",
    //                 "url": "pin_code_search",
    //                 "request": {
    //                     "pin_code": CurrentPincode,
    //                 },
    //                 "DeviceId": device_id
    //             };
    //             wsSend_request(websocket, param);
    //         } else {
    //             Dispatch(StartCommonLoader(false));
    //         }
    //     };
    // }, [ CurrentData_ ]);



    // search pincode
    const onEnterPincodeSearch = (e) => {
        const code = e.which || e.keyCode;
        if (code === 13) {
            SearchPincode();
        };
    };

    // search pincode
    const SearchPincode = () => {
        if (ApplicationState?.PINCode?.length === 6) {
            Dispatch(StartCommonLoader(true));
            let param = {
                "transmit": "single",
                "url": "pin_code_search",
                "request": {
                    "pin_code": ApplicationState?.PINCode,
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
        } else {
            Dispatch(StartCommonLoader(false));
        }
    };

    const SearchInvoiceFunction = (e) => {
        setApplicationState({
            ...ApplicationState,
            FrameNumber: e.target.value,
        });
        if (e.target.value === "") {
            Dispatch(GetFrameList({}));
            Dispatch(ErrorFramesMessageFromRedux({
                API: "",
                Error: "",
            }));
        };
    };

    // pin code error get from redux
    useEffect(() => {
        if (ErrorMessageFromReddux?.API === "pin_code_search" && ErrorMessageFromReddux?.Error !== "") {
            seterrorPINCode(ErrorMessageFromReddux?.Error);
        };
    }, [ErrorMessageFromReddux]);

    // frame number error get from redux
    useEffect(() => {
        if (ErrorFramesMessageFromReddux?.API === "get_frame_number" && ErrorFramesMessageFromReddux?.Error !== "") {
            seterrorFrameNumber(ErrorFramesMessageFromReddux?.Error);
        };
    }, [ErrorFramesMessageFromReddux]);

    const PinCOdeSearchFunction = (e) => {
        setApplicationState({ ...ApplicationState, PINCode: e.target.value });
        if (e.target.value.length === 6) {
            Dispatch(StartCommonLoader(true));
            let param = {
                "transmit": "broadcast",
                "url": "pin_code_search",
                "request": {
                    "pin_code": e.target.value,
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);

        } if (e.target.value === "") {
            Dispatch(SearchPincodeList({}));
            Dispatch(ErrorMessageFromRedux({
                API: "",
                Error: "",
            }));
        } else {
            Dispatch(StartCommonLoader(false))
        }
    };

    // select user case
    const SelectUserCase = (value) => {
        if (value !== null) {
            setApplicationState({
                ...ApplicationState,
                UseCase: value,
            });
            seterrorUseCase("");
        } else {
            setApplicationState({
                ...ApplicationState,
                UseCase: "",
            });
            seterrorUseCase("Please select User case");
        }
    };

    // select age groups
    const SelectAgeGroups = (value) => {
        if (value !== null) {
            setApplicationState({
                ...ApplicationState,
                AgeGroup: value,
            });
            seterrorAgeGroup("");
        } else {
            setApplicationState({
                ...ApplicationState,
                AgeGroup: "",
            });
            seterrorAgeGroup("Please select age groups");
        }
    };

    // search invoice id
    const onEnterInvoiceSearch = (e) => {
        const code = e.which || e.keyCode;
        if (code === 13) {
            SearchOrderList();
        };
    };

    // search order api
    const SearchOrderList = () => {
        if (ApplicationState?.FrameNumber?.trim() !== "") {
            Dispatch(StartMainCommonLoader({
                url: "get_frame_number",
                loader: true
            }));
            let param = {
                "transmit": "broadcast",
                "url": "get_frame_number",
                "request": {
                    "frame_number": ApplicationState?.FrameNumber?.trim(),
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
        } else {
            Dispatch(StartMainCommonLoader({
                url: "get_frame_number",
                loader: false
            }));
        };
    };

    // upload attachments
    const UploadImage = async (e) => {
        const files = e.target.files[0];
        if (files) {
            setLoaderFile(true);
            const formData = new FormData();
            formData.append("file", files, files.name);
            const fileresponse = await UploadFileAPI(accessToken, formData);
            if (fileresponse?.status === 200) {
                setAttachment({
                    ...Attachment,
                    filename: fileresponse?.name,
                    fileID: fileresponse?.id
                });
                setTimeout(() => {
                    setLoaderFile(false);
                }, 1000);
                document.getElementById('fileInput').value = null;
            } else {
                setLoaderFile(false);
                // setUploadFileLoader(false);
                setAttachment({
                    ...Attachment,
                    filename: "",
                    fileID: ""
                });
            };
        };
    };

    // Product select
    const ProductSelect = (value) => {
        if (value !== null) {
            setApplicationState({
                ...ApplicationState,
                ProductName: value,
            });
            CheckValid(value, { type: 'ProductName', error: seterrorProductName });
        } else {
            setApplicationState({
                ...ApplicationState,
                ProductName: "",
            });
            CheckValid(value, { type: 'ProductName', error: seterrorProductName });
        }
    };

    // franchise select fucntion
    const FranchiseSelect = (value) => {
        if (value !== null) {
            setApplicationState({
                ...ApplicationState,
                DealerInfo: value,
            });
            CheckValid(value, { type: 'DealerInfo', error: seterrorDealerInfo });
        } else {
            setApplicationState({
                ...ApplicationState,
                DealerInfo: "",
            });
            CheckValid(value, { type: 'DealerInfo', error: seterrorDealerInfo });
        }
    };

    const PhoneCode = 91;
    const CountryStates = getRegionCodeForCountryCode(PhoneCode);
    const CheckMobileNumber = parsePhoneNumber(ApplicationState?.Mobile?.toString(), { regionCode: CountryStates });

    // create and edit brand
    const SubmitFunction = async () => {
        if (ApplicationState?.CustomerName.trim() !== "" && ApplicationState?.ProductName !== "" && ApplicationState?.ProductValue.trim() !== "" &&
            ApplicationState?.Mobile.trim() !== "" && ApplicationState?.Email.trim() !== "" && 
            // ApplicationState?.FrameColor !== "" &&
            ApplicationState?.UseCase !== "" && ApplicationState?.FrameNumber.trim() !== "" &&
            ApplicationState?.AgeGroup !== "" && ApplicationState?.PINCode.trim() !== "" && ApplicationState?.DealerInfo !== ""
        ) {
            if (EmailRegex.test(ApplicationState?.Email) === true && CheckMobileNumber?.valid === true && ApplicationState?.PINCode.trim()?.length === 6) {
                let param = {
                    "transmit": "broadcast",
                    "url": "purchase_edit",
                    "request": {
                        "purchase_id": CurrentData_?.id,
                        "customer_name": ApplicationState?.CustomerName,
                        "mobile": ApplicationState?.Mobile,
                        "email": ApplicationState?.Email,
                        "use_case": ApplicationState?.UseCase?.value,
                        "age_group": ApplicationState?.AgeGroup?.value,
                        "pin_code_id": searchPincodeList?.pin?.id,
                        "district_id": searchPincodeList?.city?.id,
                        "state_id": searchPincodeList?.state?.id,
                        "frame_number": ApplicationState?.FrameNumber,
                        "product_id": ApplicationState?.ProductName?.value,
                        "product_name": ApplicationState?.ProductName?.label,
                        "franchise_id": ApplicationState?.DealerInfo?.value,
                        "franchise_name": ApplicationState?.DealerInfo?.label,
                        "invoice_image": Attachment?.fileID,
                        "invoice_image_name": Attachment?.filename,
                        "frame_available": ApplicationState?.FrameAvailable,
                        "product_value": ApplicationState?.ProductValue,
                    },
                    "DeviceId": device_id
                };
                wsSend_request(websocket, param)
                seterrorAgeGroup("");
                seterrorUseCase("");
            } else {
                CheckValid(ApplicationState?.Mobile, { type: 'phone', error: seterrorMobile });
                CheckValid(ApplicationState?.Email.trim(), { type: 'email', error: seterrorEmail });
                CheckValid(ApplicationState?.PINCode.trim(), { type: 'PINCode', error: seterrorPINCode });
            };
        } else {
            CheckValid(ApplicationState?.CustomerName.trim(), { type: 'Customername', error: seterrorCustomerName });
            CheckValid(ApplicationState?.Email.trim(), { type: 'email', error: seterrorEmail });
            CheckValid(ApplicationState?.Mobile.trim(), { type: 'phone', error: seterrorMobile });
            CheckValid(ApplicationState?.PINCode.trim(), { type: 'PINCode', error: seterrorPINCode });
            CheckValid(ApplicationState?.DealerInfo, { type: 'DealerInfo', error: seterrorDealerInfo });
            // CheckValid(ApplicationState?.FrameColor, { type: 'FrameColor', error: seterrorFrameColor });
            CheckValid(ApplicationState?.ProductName, { type: 'ProductName', error: seterrorProductName });
            CheckValid(ApplicationState?.FrameNumber, { type: 'FrameNumber', error: seterrorFrameNumber });
            CheckValid(ApplicationState?.ProductValue.trim(), { type: 'productValue', error: seterrorProductValue })

            if (ApplicationState?.AgeGroup !== "") {
                seterrorAgeGroup("");
            } else {
                seterrorAgeGroup("Please select age groups");
            };
            if (ApplicationState?.UseCase !== "") {
                seterrorUseCase("");
            } else {
                seterrorUseCase("Please select User case");
            };
        };
    };

    // back clear data
    const clearDataAndRedux = () => {
        setTimeout(() => {
            Dispatch(SearchPincodeList({}));
            Dispatch(GetFrameList({}));
            Dispatch(ErrorMessageFromRedux({
                API: "",
                Error: "",
            }));
            Dispatch(ErrorFramesMessageFromRedux({
                API: "",
                Error: "",
            }));
        }, 500);
    };

    useEffect(() => {
        if (ClearFormSet?.action === 200 && ClearFormSet?.url === "purchase_edit") {
            Navigate(PURCHASE_LIST_URL);
            clearDataAndRedux();
            Dispatch(SearchPincodeList({}));
            Dispatch(GetFrameList({}));
            Dispatch(ErrorMessageFromRedux({
                API: "",
                Error: "",
            }));
        };
    }, [ClearFormSet]);

    return (<section className="createApplcation_wrapper">
        <div className="MainHeader">
            <Link 
                to={PURCHASE_LIST_URL} 
                onClick={() => {
                    clearDataAndRedux();  
                    Dispatch(SearchPincodeList({}));
                    Dispatch(GetFrameList({}));
                }} className="btn btn-link backbtn"><i className="bi bi-arrow-left-short"></i> Back</Link>
        </div>
        <div className="tabsWrappersmAN">
            <Scrollbars
                style={{ height: "calc(100vh - 147px)" }}
                renderView={props => <div {...props} className="view" />}
                
                className="ScrollbarsSidebar"
            >
                <div className="formloginset">
                    <div className="row">
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>Customer Name <span className="mendatory_star">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    value={ApplicationState?.CustomerName}
                                    onChange={(e) => setApplicationState({ ...ApplicationState, CustomerName: e.target.value })}
                                    onKeyUp={(e) => CheckValid(e.target.value, { type: 'Customername', error: seterrorCustomerName })}
                                    onKeyDown={EmptySpaceFieldValid}
                                    placeholder="Enter Customer Name"
                                />
                                {errorCustomerName !== "" && <span className="error_medotory">{errorCustomerName}</span>}
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>Email ID <span className="mendatory_star">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    value={ApplicationState?.Email}
                                    onChange={(e) => setApplicationState({ ...ApplicationState, Email: e.target.value })}
                                    onKeyUp={(e) => CheckValid(e.target.value, { type: 'email', error: seterrorEmail })}
                                    onKeyDown={EmptySpaceFieldValid}
                                    placeholder="Enter Email ID"
                                />
                                {errorEmail !== "" && <span className="error_medotory">{errorEmail}</span>}
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>Mobile Number <span className="mendatory_star">*</span></Form.Label>
                                <Form.Control
                                    type="number"
                                    className="inputBoxs"
                                    value={ApplicationState?.Mobile}
                                    onChange={(e) => setApplicationState({ ...ApplicationState, Mobile: e.target.value.replace(/^0/, "") })}
                                    onKeyUp={(e) => CheckValid(e.target.value, { type: 'phone', error: seterrorMobile })}
                                    onKeyDown={(e) => {
                                        EmptySpaceFieldValid(e)
                                        exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                    }}
                                    placeholder="Enter Mobile Number"
                                />
                                {errorMobile !== "" && <span className="error_medotory">{errorMobile}</span>}
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>Use Case <span className="mendatory_star">*</span></Form.Label>
                                <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                    <Select
                                        value={ApplicationState?.UseCase}
                                        onChange={(e) => SelectUserCase(e)}
                                        isClearable
                                        options={optionsUseCase}
                                        className="godown-select-container"
                                        classNamePrefix="godown-select"
                                        styles={customStyles}
                                        placeholder="Select Use Case"
                                    />
                                </div>
                                {errorUseCase !== "" && <span className="error_medotory">{errorUseCase}</span>}
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>Age Group <span className="mendatory_star">*</span></Form.Label>
                                <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                    <Select
                                        value={ApplicationState?.AgeGroup}
                                        onChange={(e) => SelectAgeGroups(e)}
                                        isClearable
                                        options={optionsAgeGroups}
                                        className="godown-select-container"
                                        classNamePrefix="godown-select"
                                        styles={customStyles}
                                        placeholder="Select Age Group*"
                                    />
                                </div>
                                {errorAgeGroup !== "" && <span className="error_medotory">{errorAgeGroup}</span>}
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>
                                    PIN Code
                                    <span className="mendatory_star">*</span>
                                    <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 250, hide: 100 }}
                                        overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                                            Search by either hitting the enter button or clicking on the search icon.
                                        </Tooltip>}
                                    >
                                        <small className="filedinfomsg"><i className="bi bi-info-circle-fill"></i></small>
                                    </OverlayTrigger>
                                </Form.Label>
                                <div className="serachBoxWIth">
                                    <Form.Control
                                        type="text"
                                        value={ApplicationState?.PINCode}
                                        onChange={(e) => PinCOdeSearchFunction(e)}
                                        onKeyUp={(e) => CheckValid(e.target.value, { type: 'PINCode', error: seterrorPINCode })}
                                        onKeyDown={(e) => {
                                            EmptySpaceFieldValid(e);
                                            onEnterPincodeSearch(e)
                                        }}
                                        disabled={commonLoader}
                                        maxLength={6}
                                        placeholder="Enter PIN Code"
                                    />
                                    <Button className="searchbtn" onClick={() => SearchPincode()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                                            <path d="M21 21l-6 -6"></path>
                                        </svg>
                                    </Button>
                                    {ErrorMessageFromReddux?.API === "pin_code_search" && (<>
                                        <div className="pincode_error">
                                            {ErrorMessageFromReddux?.Error !== "" ? (
                                                <i className="bi bi-x-circle-fill" onClick={() => setApplicationState({ ...ApplicationState, PINCode: "" })} style={{ color: "red" }}></i>
                                            ) : (<i className="bi bi-check-circle-fill" style={{ color: "#10b068" }}></i>)}
                                        </div>
                                    </>)}
                                    {commonLoader && (<Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>)}
                                </div>
                                {errorPINCode !== "" && <span className="error_medotory">{errorPINCode}</span>}
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>City</Form.Label>
                                <div className="defaultvalueField">
                                    {ErrorMessageFromReddux?.Error === "" ? searchPincodeList?.city?.district_name : ""}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>State</Form.Label>
                                <div className="defaultvalueField">
                                    {ErrorMessageFromReddux?.Error === "" ? searchPincodeList?.state?.state_name : ""}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                        <Form.Group className="mb-3" >
                            <Form.Label>Product Value <span className="mendatory_star">*</span></Form.Label>
                            <Form.Control
                                type="number"
                                className="inputBoxs"
                                value={ApplicationState?.ProductValue}
                                onChange={(e) => setApplicationState({ ...ApplicationState, ProductValue: e.target.value.replace(/^0/, "") })}
                                onKeyUp={(e) => CheckValid(e.target.value, { type: 'productValue', error: seterrorProductValue })}
                                onKeyDown={(e) => {
                                    EmptySpaceFieldValid(e)
                                    exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                }}
                                placeholder="Enter Product Value"
                                min="0"
                            />
                            {errorProductValue !== "" && <span className="error_medotory">{errorProductValue}</span>}
                        </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>
                                    Frame Number
                                    <span className="mendatory_star">*</span>
                                    <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 250, hide: 100 }}
                                        overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                                            Search by either hitting the enter button or clicking on the search icon.
                                        </Tooltip>}
                                    >
                                        <small className="filedinfomsg"><i className="bi bi-info-circle-fill"></i></small>
                                    </OverlayTrigger>
                                </Form.Label>
                                <div className="serachBoxWIth">
                                    <Form.Control
                                        type="text"
                                        value={ApplicationState?.FrameNumber}
                                        onChange={(e) => SearchInvoiceFunction(e)}
                                        onKeyUp={(e) => CheckValid(e.target.value, { type: 'FrameNumber', error: seterrorFrameNumber })}
                                        onKeyDown={(e) => {
                                            EmptySpaceFieldValid(e);
                                            onEnterInvoiceSearch(e)
                                        }}
                                        disabled={(commonMainLoader?.url === "get_frame_number" && commonMainLoader?.loader)}
                                        placeholder="Search Frame Number"
                                    />
                                    <Button
                                        className="searchbtn"
                                        onClick={() => SearchOrderList()}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                                            <path d="M21 21l-6 -6"></path>
                                        </svg>
                                    </Button>
                                    {ErrorFramesMessageFromReddux?.API === "get_frame_number" && (<>
                                        <div className="pincode_error">
                                            {ErrorFramesMessageFromReddux?.Error !== "" ? (
                                                <i className="bi bi-x-circle-fill" onClick={() => setApplicationState({ ...ApplicationState, FrameNumber: "" })} style={{ color: "red" }}></i>
                                            ) : (<i className="bi bi-check-circle-fill" style={{ color: "#10b068" }}></i>)}
                                        </div>
                                    </>)}
                                    {(commonMainLoader?.url === "get_frame_number" && commonMainLoader?.loader) && (<Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>)}
                                </div>
                                {errorFrameNumber !== "" && (<span className="error_medotory">{errorFrameNumber}</span>)}
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>Product Name <span className="mendatory_star">*</span></Form.Label>
                                {/* <Form.Control
                                    type="text"
                                    value={ApplicationState?.ProductName}
                                    onChange={(e) => setApplicationState({ ...ApplicationState, ProductName: e.target.value })}
                                    onKeyUp={(e) => CheckValid(e.target.value, { type: 'ProductName', error: seterrorProductName })}
                                    onKeyDown={EmptySpaceFieldValid}
                                    placeholder="Enter Product Name"
                                /> */}
                                <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                    <Select 
                                        onChange={(e) => ProductSelect(e)} 
                                        value={ApplicationState?.ProductName}
                                        isClearable 
                                        options={optionsProduct}
                                        className="godown-select-container" 
                                        classNamePrefix="godown-select"
                                        placeholder="Select Products"
                                        styles={customStyles}
                                    />
                                </div>
                                {errorProductName !== "" && <span className="error_medotory">{errorProductName}</span>}
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>Dealer Info <span className="mendatory_star">*</span></Form.Label>
                                {/* <Form.Control
                                    type="text"
                                    value={ApplicationState?.DealerInfo}
                                    onChange={(e) => setApplicationState({ ...ApplicationState, DealerInfo: e.target.value })}
                                    onKeyUp={(e) => CheckValid(e.target.value, { type: 'DealerInfo', error: seterrorDealerInfo })}
                                    onKeyDown={EmptySpaceFieldValid}
                                    placeholder="Enter Dealer Info"
                                /> */}
                                <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                    <Select
                                        value={ApplicationState?.DealerInfo}
                                        onChange={(e) => FranchiseSelect(e)}
                                        isClearable
                                        options={FranchiseListOption}
                                        className="godown-select-container"
                                        classNamePrefix="godown-select"
                                        placeholder="Select Franchise"
                                        styles={customStyles}
                                    />
                                </div>
                                {errorDealerInfo !== "" && <span className="error_medotory">{errorDealerInfo}</span>}
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>Frame Available</Form.Label>
                                <div className="ToggleMainWrap">
                                    <CommonToggle 
                                        valueToggle={ApplicationState?.FrameAvailable}
                                        setValueToggle={() => setApplicationState({...ApplicationState, FrameAvailable: !ApplicationState?.FrameAvailable})}
                                        name={""}
                                    />
                                    {ApplicationState?.FrameAvailable ? <Badge bg="success">Yes</Badge> : <Badge bg="danger">No</Badge>}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3 ">
                                <Form.Label>Upload Invoice</Form.Label>
                                {Attachment?.fileID === "" ? (<label id="UploadCmnBTN" className="btn head_cmn_btn w-100 UploadCmnBTN UploadCmnAttchmentBTN" style={{ width: "auto", marginTop: "0px", float: "left" }}>
                                    <i className="bi bi-upload"></i>
                                    <input type="file" name={"Attachments"} id="fileInput" onChange={(e) => UploadImage(e)} accept=".png, .jpg, .pdf" />
                                    Upload Invoice
                                    {LoaderFile && (<Spinner />)}
                                </label>) : (<div className="uplaodedFilename w-100">
                                    <i className="bi bi-file-earmark-text"></i>
                                    {Attachment?.filename}
                                    <i className="bi bi-x-circle-fill"
                                        onClick={() => {
                                            setAttachment({
                                                ...Attachment,
                                                filename: "",
                                                fileID: ""
                                            })
                                        }}></i>
                                </div>)}
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Button className="formbtn widthfullbtn" style={{ width: "auto" }} onClick={() => SubmitFunction()} variant="primary" >Update</Button>
                        </div>
                    </div>
                </div>
            </Scrollbars>
        </div>
    </section>)
}

export default EditPurchase;