/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Badge, Button, Form } from 'react-bootstrap';
import CommonToggle from "../../Common/CommonToggle";
import { Link, useNavigate, useLocation, useParams  } from "react-router-dom";
import { STATES_URL } from "../../Shared/constant";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import CheckValid from "../../Common/CheckValid";
import { useSelector, useDispatch } from "react-redux";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import Select from 'react-select';
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";

const AddStates = () => {
    const Dispatch = useDispatch();
    const Navigate = useNavigate();
    const LocationRoute = useLocation()?.pathname?.split("/")[1];
    const { websocket } = useContext(WebSocketContext);
    const [ UploadFileLoader, setUploadFileLoader ] = useState(false);
    const [ ApplicationState, setApplicationState ] = useState({
        StateName: "",
        StateCode: "",
        GSTCode: "",
        RegionValue: "",
        Status: true
    });
    const roomId = useParams()?.id;
    const { Regionlistall, ClearFormSet, device_id, stateList } = useSelector((state) => state.adminReducers);
    const stateData = stateList?.list?.filter((elm) => elm?.id === roomId)[0];
    const [ optionsRegion, SetOptionsRegion ] = useState(()=>Regionlistall?.filter((elm) => elm?.is_active === true)?.map((item, index)=>({key:item.id, value:item?.id , label:item?.region_name })));
    // error state
    const [ errorStateName, seterrorStateName ] = useState("");
    const [ errorStateCode, seterrorStateCode ] = useState("");
    const [ errorGSTCode, seterrorGSTCode ] = useState("");
    const [ errorRegionValue, seterrorRegionValue ] = useState("");
    
    const exceptThisCostSymbols = ["e", "E", "+", "-", "."];
    
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };
    
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "region_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // Region Value Select
    const RegionValueSelect = (value) => {
        if(value !== null) {
            setApplicationState({...ApplicationState, RegionValue: value});
            CheckValid(value, {type: 'RegionSelect', error: seterrorRegionValue});
        } else {
            setApplicationState({...ApplicationState, RegionValue: ""});
            CheckValid(value === null ? "" : value, {type: 'RegionSelect', error: seterrorRegionValue});
        }
    };

    useEffect(() => {
        if(LocationRoute === "edit-state") {
            const data = optionsRegion?.filter((eml)=>stateData?.region_id === eml?.value)
            setApplicationState({
                ...ApplicationState,
                StateName:stateData?.state_name,
                StateCode:stateData?.state_code,
                GSTCode:stateData?.gst_code,
                Status: stateData?.is_active,
                RegionValue: data[0],
            });
        };
    }, [ stateData ])
    // useEffect(() => {
    //     if(LocationRoute === "edit-product-application") {
    //         setApplicationState({
    //             ...ApplicationState,
    //             BrandImage: CurrentData_?.brand_image,
    //             BrandName: CurrentData_?.brand_name,
    //             Status: CurrentData_?.is_active
    //         });
    //     };
    // }, [ CurrentData_ ])

    // create and edit brand
    const CreateBrand = () => {
        if(ApplicationState?.StateName?.trim() !== "" && ApplicationState?.StateCode?.trim() !== "" && 
        ApplicationState?.GSTCode?.trim() !== "" && ApplicationState?.RegionValue !== "") {
            if(LocationRoute === "edit-state") {
                Dispatch(ClearFormSetFutios({
                    url: "state_edit",
                    action: true
                }));
                let param = { 
                    "transmit": "broadcast", 
                    "url": "state_edit",
                    "request" : { 
                        "state_id": stateData?.id,
                        "state_name" : ApplicationState?.StateName.trim(), 
                        "state_code" : ApplicationState?.StateCode.trim(),
                        "gst_code" : ApplicationState?.GSTCode.trim(),
                        "region_id": ApplicationState?.RegionValue?.value,
                        "is_active": ApplicationState?.Status
                    },
                    "DeviceId" : device_id
                };
                wsSend_request(websocket, param);
            } else {
                Dispatch(ClearFormSetFutios({
                    url: "state_add",
                    action: true
                }));
                let param = { 
                    "transmit": "broadcast", 
                    "url": "state_add",
                    "request" : { 
                        "state_name" : ApplicationState?.StateName.trim(), 
                        "state_code" : ApplicationState?.StateCode.trim(),
                        "gst_code" : ApplicationState?.GSTCode.trim(),
                        "region_id": ApplicationState?.RegionValue?.value,
                        "is_active": ApplicationState?.Status
                    },
                    "DeviceId" : device_id
                };
                wsSend_request(websocket, param);
            }
        } else {
            CheckValid(ApplicationState?.StateName?.trim(), {type: 'StateName', error: seterrorStateName});
            CheckValid(ApplicationState?.StateCode?.trim(), {type: 'StateCode', error: seterrorStateCode});
            CheckValid(ApplicationState?.GSTCode?.trim(), {type: 'GSTCode', error: seterrorGSTCode})
            CheckValid(ApplicationState?.RegionValue, {type: 'RegionSelect', error: seterrorRegionValue});
        };
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200 && (ClearFormSet?.url === "state_edit" || ClearFormSet?.url === "state_add")) {
            Navigate(STATES_URL);
        };
    }, [ ClearFormSet ]);

    return(<section className="createApplcation_wrapper">
        <div className="MainHeader">
            <Link to={STATES_URL} className="btn btn-link backbtn"><i className="bi bi-arrow-left-short"></i> Back</Link>
        </div>
        <div className="tabsWrappers">
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>State Name</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={ApplicationState?.StateName} 
                                onChange={(e) => setApplicationState({...ApplicationState, StateName: e.target.value})}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'StateName', error: seterrorStateName})}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter State Name" 
                                disabled={UploadFileLoader}
                            />
                            {errorStateName !== "" &&<span className="error_medotory">{errorStateName}</span>}
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>State Code</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={ApplicationState?.StateCode} 
                                onChange={(e) => setApplicationState({...ApplicationState, StateCode: e.target.value})}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'StateCode', error: seterrorStateCode})}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter State Code" 
                                disabled={UploadFileLoader}
                            />
                            {errorStateCode !== "" &&<span className="error_medotory">{errorStateCode}</span>}
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>GST Code</Form.Label>
                            <Form.Control 
                                type="number" 
                                className="inputBoxs"
                                value={ApplicationState?.GSTCode} 
                                onChange={(e) => {
                                    if (/^\d{0,2}$/.test(e.target.value)) {
                                        setApplicationState({...ApplicationState, GSTCode: e.target.value.replace(/^0/, "")})
                                    };
                                }}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'GSTCode', error: seterrorGSTCode})}
                                onKeyDown={(e) => {
                                    EmptySpaceFieldValid(e)
                                    exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                }}
                                placeholder="Enter GST Code" 
                                disabled={UploadFileLoader}
                                maxLength={2}
                                max={2}
                            />
                            {errorGSTCode !== "" &&<span className="error_medotory">{errorGSTCode}</span>}
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Select Region</Form.Label>
                            <div className="selectMultiselect" id="selectMultiselectBrand">
                                <Select 
                                    value={ApplicationState?.RegionValue}
                                    onChange={(e) => RegionValueSelect(e)} 
                                    // defaultValue={defaultCategoryOption} 
                                    isClearable 
                                    options={optionsRegion}
                                    className="godown-select-container" 
                                    classNamePrefix="godown-select"
                                    styles={customStyles}
                                    placeholder="Select Region"
                                />
                                {errorRegionValue !== "" && <span className="error_medotory">{errorRegionValue}</span>}
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Status</Form.Label>
                            <div className="ToggleMainWrap">
                                <CommonToggle 
                                    valueToggle={ApplicationState?.Status}
                                    setValueToggle={() => setApplicationState({...ApplicationState, Status: !ApplicationState?.Status})}
                                    name={""}
                                />
                                {ApplicationState?.Status ? <Badge bg="success">Active</Badge> : <Badge bg="danger">Inactive</Badge>}
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Button 
                            className="formbtn widthfullbtn" 
                            disabled={ClearFormSet?.action === true && (ClearFormSet?.url === "state_edit" || ClearFormSet?.url === "state_add")}
                            onClick={() => CreateBrand()} 
                            variant="primary" 
                        >
                        {/* {LocationRoute === "edit-product-application" ? "Edit Applications" : "Create Applications"} */}
                        {LocationRoute === "edit-state" ? "Update" : "Add"}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}

export default AddStates;