/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Badge, Button, Form } from 'react-bootstrap';
import CommonToggle from "../../Common/CommonToggle";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { SUB_CATEGORY_URL } from "../../Shared/constant";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import CheckValid from "../../Common/CheckValid";
import { useSelector, useDispatch } from "react-redux";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import Select from 'react-select';
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";

const SubCreateCategory = () => {
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const LocationRoute = useLocation()?.pathname?.split("/")[1];
    const { websocket } = useContext(WebSocketContext);
    const [ UploadFileLoader, setUploadFileLoader ] = useState(false);
    const [ subCategoryState, setSubCategoryState ] = useState({
        BrandName: "",
        CategoryName: "",
        SubCategoryName: "",
        Status: true
    });
    const { brandlistall, ClearFormSet, subcategoryList, categorylistall, device_id } = useSelector((state) => state.adminReducers);
    const [ optionsBrands, SetOptionsBrands ] = useState(()=>brandlistall?.filter((elm) => elm?.is_active === true)?.map((item, index)=>({key:item.id, value:item?.id , label:item?.brand_name })));
    const [ optionsCategory, SetOptionsCategory ] = useState(()=>categorylistall?.filter((elm) => elm?.is_active === true)?.map((item, index)=>({key:item.id, value:item?.id , label:item?.category_name })));

    // error state
    const [ errorBrandImage, seterrorBrandImage ] = useState("");
    const [ errorSubCategoryName, seterrorSubCategoryName ] = useState("");
    const [ errorCategoryName, seterrorCategoryName ] = useState("");
    const [ errorSelectBrandName, seterrorSelectBrandName ] = useState("");
    const { roomId } = useParams();
    const CurrentData_ = subcategoryList?.list?.filter((elm) => elm?.id === roomId)[0];
    let defaultBrandGet = categorylistall?.filter((elm) => elm?.id === CurrentData_?.category_id)[0];
    let defaultBrandOption = optionsBrands?.filter((elm) => elm?.key === defaultBrandGet?.brand_id)[0];

    const [ defaultCategoryOption, setdefaultCategoryOption ] = useState(() =>optionsCategory?.filter((elm) => elm?.key === CurrentData_?.category_id));

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };
    
    useEffect(() => {
        if(LocationRoute === "edit-subcategory") {
            setSubCategoryState({
                ...subCategoryState,
                BrandName: defaultBrandOption,
                CategoryName: defaultCategoryOption[0],
                SubCategoryName: CurrentData_?.sub_category_name,
                Status: CurrentData_?.is_active
            });
        };
    }, [ CurrentData_, defaultBrandOption, defaultCategoryOption ])

    // select brand name
    const BrandSelect = (value) => {
        setSubCategoryState({...subCategoryState, CategoryName: null});
        if(value !== null) {
            setSubCategoryState({...subCategoryState, BrandName: value, CategoryName: ""});
            CheckValid(value, {type: 'SelectBrand', error: seterrorSelectBrandName});
        } else {
            setSubCategoryState({...subCategoryState, BrandName: "", CategoryName: ""});
            CheckValid(value === null ? "" : value, {type: 'SelectBrand', error: seterrorSelectBrandName});
            SetOptionsCategory(categorylistall?.map((item, index)=>({key:item.id, value:item?.id , label:item?.category_name })));
        }
    };

    useEffect(() => {
        if(subCategoryState?.BrandName) {
            const categorylistallFilter = categorylistall?.filter((elm) => {
                if(elm?.brand_id === subCategoryState?.BrandName?.value) {
                    return elm;
                };
            })
            SetOptionsCategory(categorylistallFilter?.map((item, index)=>({key:item.id, value:item?.id , label:item?.category_name })))
        }
    }, [ subCategoryState?.BrandName ]);

    // select category name
    const CategorySelect = (value) => {
        if(value !== null) {
            setSubCategoryState({...subCategoryState, CategoryName: value});
            CheckValid(value, {type: 'SelectCategory', error: seterrorCategoryName});
        } else {
            setSubCategoryState({...subCategoryState, CategoryName: ""});
            CheckValid(value === null ? "" : value, {type: 'SelectCategory', error: seterrorCategoryName});
        }
    };

    // create SUB category
    const CreateCategory = () => {
        if(subCategoryState?.BrandName !== "" && subCategoryState?.CategoryName !== "" && subCategoryState?.SubCategoryName.trim() !== "") {
            if(LocationRoute === "edit-subcategory") {
                Dispatch(ClearFormSetFutios({
                    url: "subcategory_edit",
                    action: true
                }));
                let param = { 
                    "transmit": "broadcast", 
                    "url": "subcategory_edit",
                    "request" : { 
                        "sub_category_id" : CurrentData_?.id,
                        "sub_category_name" : subCategoryState?.SubCategoryName.trim(),
                        "category_id" : subCategoryState?.CategoryName?.value,
                        "is_active" : subCategoryState?.Status
                    },
                    "DeviceId" : device_id
                };
                wsSend_request(websocket, param);
            } else {
                Dispatch(ClearFormSetFutios({
                    url: "subcategory_add",
                    action: true
                }));
                let param = { 
                    "transmit": "broadcast", 
                    "url": "subcategory_add",
                    "request" : { 
                        "sub_category_name" : subCategoryState?.SubCategoryName.trim(),
                        "category_id" : subCategoryState?.CategoryName?.value,
                        "is_active" : subCategoryState?.Status
                    },
                    "DeviceId" : device_id
                };
                wsSend_request(websocket, param);
            }
        } else {
            CheckValid(subCategoryState?.SubCategoryName.trim(), {type: 'SubCategoryName', error: seterrorSubCategoryName})
            CheckValid(subCategoryState?.CategoryName, {type: 'SelectCategory', error: seterrorCategoryName});
            CheckValid(subCategoryState?.BrandName, {type: 'SelectBrand', error: seterrorSelectBrandName});
        };
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200 && (ClearFormSet?.url === "subcategory_add" || ClearFormSet?.url === "subcategory_edit")) {
            Navigate(SUB_CATEGORY_URL);
        };
    }, [ ClearFormSet ]);

    return(<section className="createApplcation_wrapper">
        <div className="MainHeader">
            <Link to={SUB_CATEGORY_URL} className="btn btn-link backbtn"><i className="bi bi-arrow-left-short"></i> Back</Link>
        </div>
        <div className="tabsWrappers">
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Brands<span className="mendatory_star">*</span></Form.Label>
                            <div className="selectMultiselect" id="selectMultiselectBrand">
                                <Select 
                                    value={subCategoryState?.BrandName}
                                    onChange={(e) => BrandSelect(e)} 
                                    defaultValue={defaultBrandOption} 
                                    isClearable 
                                    options={optionsBrands}
                                    className="godown-select-container" 
                                    classNamePrefix="godown-select"
                                    styles={customStyles}
                                />
                                {errorSelectBrandName !== "" && <span className="error_medotory">{errorSelectBrandName}</span>}
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Category Name<span className="mendatory_star">*</span></Form.Label>
                            <div className="selectMultiselect" id="selectMultiselectBrand">
                                <Select 
                                    value={subCategoryState?.CategoryName}
                                    onChange={(e) => CategorySelect(e)} 
                                    defaultValue={defaultCategoryOption} 
                                    isClearable 
                                    options={optionsCategory}
                                    className="godown-select-container" 
                                    classNamePrefix="godown-select"
                                    styles={customStyles}
                                />
                                {errorCategoryName !== "" && <span className="error_medotory">{errorCategoryName}</span>}
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Sub Category Name<span className="mendatory_star">*</span></Form.Label>
                            <Form.Control 
                                type="text" 
                                value={subCategoryState?.SubCategoryName} 
                                onChange={(e) => setSubCategoryState({...subCategoryState, SubCategoryName: e.target.value})}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'SubCategoryName', error: seterrorSubCategoryName})}
                                onKeyDown={EmptySpaceFieldValid}
                                disabled={UploadFileLoader}
                                placeholder="Enter Sub Category Name" 
                            />
                            {errorSubCategoryName !== "" && <span className="error_medotory">{errorSubCategoryName}</span>}
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Status</Form.Label>
                            <div className="ToggleMainWrap">
                                <CommonToggle 
                                    valueToggle={subCategoryState?.Status}
                                    setValueToggle={() => setSubCategoryState({...subCategoryState, Status: !subCategoryState?.Status})}
                                    name={""}
                                />
                                <Badge bg="success">Active</Badge>
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Button 
                            className="formbtn widthfullbtn" 
                            disabled={ClearFormSet?.action === true && (ClearFormSet?.url === "subcategory_add" || ClearFormSet?.url === "subcategory_edit")}
                            onClick={() => CreateCategory()} 
                            variant="primary" 
                        >
                            {LocationRoute === "edit-subcategory" ? "Update" : "Add"}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}

export default SubCreateCategory;