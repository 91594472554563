/* eslint-disable */
import { useEffect } from 'react';
import { ws_response } from './ws/ws_response';
import { useDispatch } from 'react-redux';
import { checkLogin, clearRedux, StartMainCommonLoader, StartCommonLoader } from '../redux/actions/adminActions';

const useWebSocket = (url, functions, accessToken, setWebsocketCheck) => {
  const Dispatch = useDispatch();

  useEffect(() => {
    if(accessToken !== "" && accessToken !== null && url?.split("/")[4] !== "null") {
      function connectWebSocket() {
          const socket = new WebSocket(url);
          functions(socket);
          // Set up event handlers
          socket.onmessage = function (evt) {
              Dispatch(ws_response({evt:evt, ws:socket }));
              setWebsocketCheck("");
          };
          socket.onclose = (event) => {
              console.log("websocket close")
              setWebsocketCheck("websocket close");

              Dispatch(StartMainCommonLoader({
                url: "",
                loader: false
              }));
              Dispatch(StartCommonLoader(false));

              // Reconnect after a delay
              if(localStorage.getItem("access_token") !== null) {
                // setTimeout(() => {
                //     console.log("Reconnecting...");
                //     connectWebSocket();
                // }, 1000); // Adjust the delay as needed
              } else {
                  Dispatch(clearRedux());
                  Dispatch(checkLogin('no'));
                  localStorage.clear();
                  localStorage.removeItem("access_token");
              }
          };
          return () => {
            socket.close();
          };
      };
      
      // Initial connection
      connectWebSocket();
    }
  }, [url]);
};

export default useWebSocket;