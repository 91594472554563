/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Badge } from 'react-bootstrap';
import DataTableFilter from "../../Common/DataTableFilter";
import AvatarSet from "../../Common/AvatarSet";
// import CommonToggle from "../../Common/CommonToggle";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Link, useNavigate } from "react-router-dom";
import { WebSocketContext } from "../../../App";
import { CREATE_USER_MANAGEMENT_URL } from "../../Shared/constant";
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonPagination from "../../Common/CommonPagination";
import CommonewModel from "../../Common/CommonewModel";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";

const User = (props) => {
    const { RouteName } = props;
    const { userList, device_id, ClearFormSet, selfInfo, user_id } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ userTeamFilter, setUserTeamFilter ] = useState("");
    const [ showModalNew, setShowModalNew ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // get product list
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.AdminTools[0]?.Edit || PermissionsAccess?.AdminTools[0]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "region_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // get product list
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.AdminTools[0]?.Edit || PermissionsAccess?.AdminTools[0]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "warehouse_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

     // get all franchise list
     useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.AdminTools[0]?.Edit || PermissionsAccess?.AdminTools[0]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "franchise_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // api calling
    const APICall = () => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.AdminTools[0]?.Edit || PermissionsAccess?.AdminTools[0]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "user_list",
                "request" : { 
                    "status" : userType === "active" ? true : userType === "inactive" ? false : "", 
                    "limit" : userLimit,
                    "page_no" : currentPage, 
                    "user_type" : userTeamFilter,
                    "search" : userSearch.trim()
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    };
    
    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket, userSearch, userType, currentPage, userLimit, userTeamFilter ]);

    useEffect(() => {
        if(ClearFormSet?.action === 200) {
            Dispatch(ClearFormSetFutios({
                url:"",
                action:false
            }));
        };
    }, [ ClearFormSet ]);

    // Get Region
    const GetRegionName = (region_id) => {
        if(region_id !== undefined) {
            const CurrentRegion = userList?.region_list?.filter((elm) => elm?.id === region_id)[0];
            return CurrentRegion?.region_name;
        } else {
            return "-";
        };
    };

    // create applications
    const cmnFnct = () => {
        Navigate(CREATE_USER_MANAGEMENT_URL);
    };

    return(<section className="Main_section">
        <DataTableFilter 
            filterType={"Users"}
            searchType={"Name, Email, Mobile"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            userTeamFilter={userTeamFilter} 
            setUserTeamFilter={setUserTeamFilter}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.AdminTools[0]?.Edit) ? true : false}
            ButtonSet={{
                buttontitle: "Create User",
                function: cmnFnct
            }}
            searchdisable={true}
            ActiveInactiveFilter={true}
        />
        <div className="tableView">
            <Scrollbars 
                style={{ height: userList?.pagination?.total_records > 10 ? "calc(100vh - 185px)" : "calc(100vh - 150px)"}} 
                renderView={props => <div {...props} className="view"/>}
                className="ScrollbarsSidebar"
            >
                <Table bordered  >
                    <thead>
                        <tr>
                            <th>SNO</th>
                            <th>Name</th>
                            <th>User Type</th>
                            <th>Region</th>
                            <th>Email</th>
                            <th>Mobile</th>
                            <th>User Verification</th>
                            <th>Status</th>
                            {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.AdminTools[0]?.Edit) && (<th>Action</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {userList?.list?.map((elm, index) => {
                            return(<tr className={elm?.id === user_id ? "active_user" : ""} key={index}>
                                <td>
                                    {GetCurrentTableSRNo(index, userList?.pagination?.current_page, userList?.pagination?.record_limit)}
                                </td>
                                <td>{elm?.id !== user_id ? elm?.full_name : "You"}</td>
                                <td style={{ width: "130px", textTransform: "capitalize" }} >{elm?.user_type === "regional_sales_team_asm" ? "Assistant Sales Manager" : elm?.user_type === "regional_sales_team_rm" ? "Regional Manager" : elm?.user_type?.replaceAll("_", " ")}</td>
                                <td>{(elm?.user_type === "regional_manager" || elm?.user_type === "regional_sales_team_rm") ? GetRegionName(elm?.related_id) : elm?.user_type === "regional_sales_team_asm" ? GetRegionName(elm?.region_id) : "-"}</td>
                                <td>{elm?.email}</td>
                                <td>{(elm?.mobile === null || elm?.mobile === "") ? "-" : elm?.mobile}</td>
                                <td>{elm?.user_type === "sale_franchise_person" ? elm?.user_verification ? (<Badge bg="success">Yes</Badge>) : (<Badge bg="danger">No</Badge>) : "-"}</td>
                                <td>{elm?.is_active ? (<Badge bg="success">Active</Badge>) : (<Badge bg="danger">Inactive</Badge>)}</td>
                                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.AdminTools[0]?.Edit) && (<td>
                                    {(elm?.user_type !== 'super_admin' && elm?.id !== user_id) && (<Link className="btn edittable" to={`/edit-user/${elm?.id}`}>
                                        <i className="bi bi-pencil-square"></i>
                                    </Link>)}
                                </td>)}
                            </tr>)
                        })}
                        {userList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="8">User list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {/* pagination */}
            {userList?.pagination?.total_records > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={userList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
        {showModalNew?.open && (<CommonewModel setCurrentPage={setCurrentPage} setShowModalNew={setShowModalNew} showModalNew={showModalNew}/>)}
    </section>)
}

export default User;